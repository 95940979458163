import { Elements, RecurlyProvider } from "@recurly/react-recurly";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { CourseDetails } from "./components/course/CourseDetails";
import { Courses } from "./components/course/Courses";
import { Login } from "./components/login/Login";
import Privacy from "./components/login/Privacy";
import Terms from "./components/login/Terms";
import { Otp } from "./components/otp/Otp";
import { PartOneScreenOne } from "./components/part-1/PartOneScreenOne";
import { PartOneScreenSecond } from "./components/part-1/PartOneScreenSecond";
import { PartOneScreenThird } from "./components/part-1/PartOneScreenThird";
import { PartTwoIntro } from "./components/part-2/PartTwoIntro";
import { PartTwoScreenFour } from "./components/part-2/PartTwoScreenFour";
import { PartTwoScreenOne } from "./components/part-2/PartTwoScreenOne";
import PartTwoScreenSeven from "./components/part-2/PartTwoScreenSeven";
import { PartTwoScreenThree } from "./components/part-2/PartTwoScreenThree";
import { PartTwoScreenTwo } from "./components/part-2/PartTwoScreenTwo";
import PartThreeEight from "./components/part-3/PartThreeEight";
import { PartThreeFive } from "./components/part-3/PartThreeFive";
import { PartThreeFour } from "./components/part-3/PartThreeFour";
import { PartThreeIntro } from "./components/part-3/PartThreeIntro";
import PartThreeOne from "./components/part-3/PartThreeOne";
import { PartThreeSeven } from "./components/part-3/PartThreeSeven";
import { PartThreeSix } from "./components/part-3/PartThreeSix";
import PartThreeThree from "./components/part-3/PartThreeThree";
import PartThreeTwo from "./components/part-3/PartThreeTwo";
import { PartFourEight } from "./components/part-4/PartFourEight";
import { PartFourEleven } from "./components/part-4/PartFourEleven";
import PartFourFourteen from "./components/part-4/PartFourFourteen";
import { PartFourIntro } from "./components/part-4/PartFourIntro";
import { PartFourNine } from "./components/part-4/PartFourNine";
import { PartFourOne } from "./components/part-4/PartFourOne";
import { PartFourSeven } from "./components/part-4/PartFourSeven";
import { PartFourTen } from "./components/part-4/PartFourTen";
import PartFourThreeNew from "./components/part-4/PartFourThreeNew";
import { PartFourTwelve } from "./components/part-4/PartFourTwelve";
import { PartFourTwo } from "./components/part-4/PartFourTwo";
import GetPlan from "./components/payment/GetPlan";
import { ManageSubscription } from "./components/subscription/ManageSubscription";
import { RoutesName } from "./utils/RoutesName";
import PaymentDuplicate from "./components/payment/PaymentNew";
import Profile from "./components/course/Profile";
import BottomTabLayout from "./layouts/BottomTabLayout";
import Meals from "./components/meal/Meals";
import MealDetails from "./components/meal/MealDetails";
import { PartFourThree } from "./components/part-4/PartFourThree";
import { PartFourFour } from "./components/part-4/PartFourFour";
import { PartFourThirteen } from "./components/part-4/PartFourThirteen";
import { PartFourFive } from "./components/part-4/PartFourFive";
import { PartFourSix } from "./components/part-4/PartFourSix";

const CheckNavigateAfterProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (
      !location.pathname.includes(RoutesName.courses) &&
      !location.pathname.includes(RoutesName.meal) &&
      location.pathname !== RoutesName.partOneAreas &&
      location.pathname !== RoutesName.partTwoWeight &&
      location.pathname !== RoutesName.partTwoGoalWeight &&
      location.pathname !== RoutesName.partThreeFive &&
      location.pathname !== RoutesName.partThreeFour &&
      location.pathname !== RoutesName.partThreeSeven
    ) {
      navigate(RoutesName.courses, { replace: true });
    } else {
      if (
        !location.state?.data &&
        (location.pathname === RoutesName.partOneAreas ||
          location.pathname === RoutesName.partTwoWeight ||
          location.pathname === RoutesName.partTwoGoalWeight ||
          location.pathname === RoutesName.partThreeFive ||
          location.pathname === RoutesName.partThreeFour ||
          location.pathname === RoutesName.partThreeSeven)
      ) {
        navigate(RoutesName.profile, { replace: true });
      }
    }
  }, []);

  return <Outlet />;
};

function App() {
  const [bodyDetails, setBodyDetails] = useState({
    currentWeight: 1,
    goalWeight: 1,
    height: 1,
    testData: {
      current: {
        weight: "",
        unit: "",
      },
      desire: {
        weight: "",
        unit: "",
      },
    },
  });

  const state = useSelector((state) => state.authState);
  const mealPlan = useSelector((state) => state.mealState);

  return (
    <Routes>
      {state.loggedIn ? (
        <>
          <Route path={RoutesName.profile} element={<Profile />} />
          <Route path="" element={<CheckNavigateAfterProfile />}>
            <Route path="" element={<BottomTabLayout />}>
              <Route path={RoutesName.courses} element={<Courses />} />
              <Route
                path={RoutesName.courseDetails}
                element={<CourseDetails />}
              />

              {mealPlan.details && (
                <>
                  <Route path={RoutesName.meal} element={<Meals />} />
                  <Route
                    path={RoutesName.mealDetails}
                    element={<MealDetails />}
                  />
                </>
              )}
            </Route>

            <Route
              path={RoutesName.partOneAreas}
              pa
              element={<PartOneScreenThird />}
            />
            <Route
              path={RoutesName.partTwoWeight}
              element={
                <PartTwoScreenTwo
                  bodyDetails={bodyDetails}
                  setBodyDetails={setBodyDetails}
                />
              }
            />
            <Route
              path={RoutesName.partTwoGoalWeight}
              element={
                <PartTwoScreenThree
                  bodyDetails={bodyDetails}
                  setBodyDetails={setBodyDetails}
                />
              }
            />
            <Route
              path={RoutesName.partThreeFive}
              element={<PartThreeFive />}
            />
            <Route
              path={RoutesName.partThreeFour}
              element={<PartThreeFour />}
            />
            <Route
              path={RoutesName.partThreeSeven}
              element={<PartThreeSeven />}
            />
          </Route>
        </>
      ) : (
        <>
          <Route
            path={RoutesName.partOneMotivate}
            element={<PartOneScreenOne />}
          />
          <Route
            path={RoutesName.partOneGoal}
            element={<PartOneScreenSecond />}
          />
          <Route
            path={RoutesName.partOneAreas}
            element={<PartOneScreenThird />}
          />
          <Route path={RoutesName.partTwoIntro} element={<PartTwoIntro />} />
          <Route
            path={RoutesName.partTwoHeight}
            element={
              <PartTwoScreenOne
                bodyDetails={bodyDetails}
                setBodyDetails={setBodyDetails}
              />
            }
          />
          <Route
            path={RoutesName.partTwoWeight}
            element={
              <PartTwoScreenTwo
                bodyDetails={bodyDetails}
                setBodyDetails={setBodyDetails}
              />
            }
          />
          <Route
            path={RoutesName.partTwoGoalWeight}
            element={
              <PartTwoScreenThree
                bodyDetails={bodyDetails}
                setBodyDetails={setBodyDetails}
              />
            }
          />
          <Route
            path={RoutesName.partTwoResult}
            element={
              <PartTwoScreenFour
                bodyDetails={bodyDetails}
                setBodyDetails={setBodyDetails}
              />
            }
          />
          <Route
            path={RoutesName.partTwoPrediction}
            element={<PartTwoScreenSeven bodyDetails={bodyDetails} />}
          />
          <Route
            path={RoutesName.partThreeIntro}
            element={<PartThreeIntro />}
          />
          <Route path={RoutesName.partThreeOne} element={<PartThreeOne />} />
          <Route path={RoutesName.partThreeTwo} element={<PartThreeTwo />} />
          <Route
            path={RoutesName.partThreeGoals}
            element={<PartThreeThree />}
          />
          <Route
            path={RoutesName.partThreeSeven}
            element={<PartThreeSeven />}
          />
          <Route path={RoutesName.partThreeFour} element={<PartThreeFour />} />
          <Route path={RoutesName.partThreeFive} element={<PartThreeFive />} />
          <Route path={RoutesName.partThreeSix} element={<PartThreeSix />} />
          <Route
            path={RoutesName.partThreePrediction}
            element={<PartThreeEight />}
          />
          <Route path={RoutesName.partFourIntro} element={<PartFourIntro />} />
          <Route path={RoutesName.partFourOne} element={<PartFourOne />} />
          <Route path={RoutesName.partFourTwo} element={<PartFourTwo />} />
          <Route
            path={RoutesName.partFourFourteen}
            element={<PartFourFourteen />}
          />
          <Route path={RoutesName.partFourThree} element={<PartFourThree />} />
          {/* <Route
            path={RoutesName.partFourThreeNew}
            element={<PartFourThreeNew />}
          /> */}
          <Route path={RoutesName.partFourFour} element={<PartFourFour />} />
          <Route path={RoutesName.partFourFive} element={<PartFourFive />} />
          <Route path={RoutesName.partFourSix} element={<PartFourSix />} />
          <Route path={RoutesName.partFourSeven} element={<PartFourSeven />} />
          <Route path={RoutesName.partFourEight} element={<PartFourEight />} />
          <Route path={RoutesName.partFourNine} element={<PartFourNine />} />
          <Route path={RoutesName.partFourTen} element={<PartFourTen />} />
          <Route
            path={RoutesName.partFourEleven}
            element={<PartFourEleven />}
          />
          <Route
            path={RoutesName.partFourTwelve}
            element={<PartFourTwelve />}
          />
          <Route
            path={RoutesName.partFourThirteen}
            element={<PartFourThirteen />}
          />
          <Route path={RoutesName.getPlan} element={<GetPlan />} />
          <Route
            path={RoutesName.payment}
            element={
              <RecurlyProvider publicKey={process.env.REACT_APP_RECURLY_KEY}>
                <Elements>
                  {/* <PaymentNew /> */}
                  <PaymentDuplicate />
                </Elements>
              </RecurlyProvider>
            }
          />
        </>
      )}
      <Route path={RoutesName.privacy} element={<Privacy />} />
      <Route path={RoutesName.terms} element={<Terms />} />
      <Route path={RoutesName.login} element={<Login />} />
      <Route path={RoutesName.otp} element={<Otp />} />
      <Route path={RoutesName.subscription} element={<ManageSubscription />} />
      <Route path="*" element={<MainLoader />} />
    </Routes>
  );
}

export default App;

const MainLoader = () => {
  const state = useSelector((state) => state.authState);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (state.loggedIn) {
        navigate(RoutesName.courses);
      } else {
        navigate(RoutesName.partOneGoal);
      }
    }, 200);
  }, []);
  return (
    <div
      className="d-flex justify-content-center align-items-center w-100"
      style={{ height: "100dvh" }}
    >
      <div
        className="spinner-border spinner-border-md text-danger mx-auto my-auto"
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
