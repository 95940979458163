import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Goal1 from "../../assets/goal-1.png";
import Goal2 from "../../assets/goal-2.png";
import Goal3 from "../../assets/goal-3.png";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { setDetailsData } from "../../slices/DetailsSlice";
import { RoutesName } from "../../utils/RoutesName";

export const PartOneScreenSecond = () => {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState([
    {
      icon: Goal1,
      text: "Slim down",
      select: false,
    },
    {
      icon: Goal2,
      text: "Remain fit",
      select: false,
    },
    {
      icon: Goal3,
      text: "Gain muscle",
      select: false,
    },
  ]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "20px",
            paddingBottom: "45px",
          }}
        >
          <p
            className="m-0 p-0"
            style={{
              fontWeight: "regular",
              fontSize: "16px",
              textDecoration: "underline",
              textAlign: "right",
            }}
            onClick={() => navigate(RoutesName.login)}
          >
            Login
          </p>
          <div style={{ flex: 1 }}>
            <h3
              className="fw-medium mt-4 text-center heading-font mb-0"
              style={{ color: "#427ee4" }}
            >
              28-DAY PILATES
              <br />
              CHALLENGE
            </h3>
            <h3 className="fw-medium mt-4 mb-2 text-center heading-font">
              What's your primary goal?
            </h3>
          </div>
          {selectedIndex.map((e, i) => (
            <Tile
              key={i}
              icon={e.icon}
              text={e.text}
              selected={e.select}
              index={i}
              setSelected={setSelectedIndex}
              list={selectedIndex}
              navigate={navigate}
            />
          ))}
        </div>
      </div>
    </MobileViewLayout>
  );
};

const Tile = ({ icon, text, selected, index, setSelected, list, navigate }) => {
  const dispatch = useDispatch();
  const handleSelect = (index) => {
    setTimeout(() => {
      navigate(RoutesName.partOneAreas);
    }, 200);
    const selectedItem = list?.find((d, i) => i === index);
    dispatch(setDetailsData({ primaryGoal: selectedItem.text }));
    const update = [...list];
    update[index].select = !update[index].select;
    setSelected([...update]);
  };
  return (
    <div
      className={`bg-white d-flex align-items-center mt-3 justify-content-between ${
        selected ? "border-main" : ""
      }`}
      style={{
        height: "130px",
        width: "100%",
        borderRadius: "15px",
      }}
      onClick={() => {
        handleSelect(index);
      }}
    >
      <h6 className="ms-3">{text}</h6>
      <img src={icon} alt="" height={"100%"} />
    </div>
  );
};
