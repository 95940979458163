import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import G1 from "../../assets/img-white.png";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";

export const PartFourThirteen = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  return (
    <MobileViewLayout>
      {" "}
      <div
        className={`animate-from-right ${isVisible ? "slide-in" : ""} bg-color`}
      >
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
            backgroundImage: `url(${G1})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div style={{ flex: 1 }}></div>
          <h1
            className="text-white mt-5 text-start"
            style={{ fontSize: "30px", fontWeight: "800" }}
          >
            Are you ready to wave goodbye to chronic diseases?
          </h1>
          <div className="d-flex">
            <button
              className="btn btn-outline-light px-4  mt-4 fw-bold"
              style={{ height: "70px" }}
              onClick={() => {
                dispatch(setDetailsData({ goodByeToDiseases: "No" }));
                navigate(RoutesName.partFourTwelve);
              }}
            >
              {" "}
              No
            </button>
            <button
              className="btn btn-light w-100 mt-4 ms-2 fw-bold"
              style={{ height: "70px" }}
              onClick={() => {
                dispatch(setDetailsData({ goodByeToDiseases: "Yes" }));
                navigate(RoutesName.partFourTwelve);
              }}
            >
              {" "}
              Yes
            </button>
          </div>
          <style>
            {`
     progress::-webkit-progress-value {
       background-color: #fff;
       width: 48px;
       height: 4px;
       border-radius: 12px;
       border: none;
     }
   `}
          </style>
        </div>
      </div>
    </MobileViewLayout>
  );
};
