import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import G1 from "../../assets/image.png";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

export const PartFourSeven = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center heading-font ">
              EazyFit was designed with people like you in mind!
            </h3>
          </div>
          <div className="" style={{ flex: 1 }}>
            <img src={G1} alt="" className="img-fluid users-img" />
            <p style={{ fontSize: "13px" }} className="m-0 p-0">
              1,000,000+ EazyFit users
            </p>
          </div>

          <h3 className="fw-bold mt-5 text-center ">
            <span className="new-text-color">83%</span> of EazyFit users
          </h3>
          <h6>
            say our workout plan is a breeze to follow, making it super easy to
            stay on the fitness journey!
          </h6>
          <button
            className="btn common-btn-color w-100 fw-bold mt-4"
            style={{ height: "70px" }}
            onClick={() => {
              navigate(RoutesName.partFourNine);
            }}
          >
            {" "}
            Next
          </button>
        </div>
      </div>
    </MobileViewLayout>
  );
};
