import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import G3 from "../../assets/camera.svg";
import G2 from "../../assets/dress.svg";
import G4 from "../../assets/luggage.svg";
import G1 from "../../assets/smile.svg";
import G5 from "../../assets/social-media.svg";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";

export const PartFourEight = () => {
  const [selectedIndex, setSelectedIndex] = useState([
    {
      icon: G1,
      text: "Have a relaxing self-care day",
      slogan: "",
      select: false,
    },
    {
      icon: G2,
      text: "Buying new clothes",
      slogan: "",
      select: false,
    },
    {
      icon: G3,
      text: "Take selfies or a photoshoot session",
      slogan: "",
      select: false,
    },
    {
      icon: G4,
      text: "Traveling somewhere new",
      slogan: "",
      select: false,
    },
    {
      icon: G5,
      text: "Post it on social media",
      slogan: "",
      select: false,
    },
  ]);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  return (
    <MobileViewLayout>
      {" "}
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center heading-font">
              Once you hit your goal weight, how will you reward yourself?
            </h3>
          </div>
          {selectedIndex.map((e, i) => (
            <Tile
              slogan={e.slogan}
              key={i}
              icon={e.icon}
              text={e.text}
              selected={e.select}
              index={i}
              setSelected={setSelectedIndex}
              list={selectedIndex}
              navigate={navigate}
            />
          ))}
        </div>
      </div>
    </MobileViewLayout>
  );
};

const Tile = ({ icon, text, selected, index, setSelected, list, navigate }) => {
  const dispatch = useDispatch();

  const handleSelect = (index) => {
    setTimeout(() => {
      navigate(RoutesName.partFourTen);
    }, 200);
    const update = [...list];
    update[index].select = !update[index].select;
    setSelected([...update]);
    setSelected([...update]);
    dispatch(setDetailsData({ rewardYourSelf: update[index].text }));
  };
  return (
    <div
      className={`bg-white d-flex align-items-center mt-3 ${
        selected ? "border-main selected-background" : ""
      }`}
      style={{
        height: "75px",
        width: "100%",
        borderRadius: "15px",
        padding: "20px 20px",
      }}
      onClick={() => {
        handleSelect(index);
      }}
    >
      <img src={icon} alt="" width={32} height={32} />

      <h6 className="ms-3 mb-0 text-start">{text}</h6>
    </div>
  );
};
