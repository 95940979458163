import React from "react";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import COURSE_IMG from "../../assets/course-img.png";
import BREAKFAST_IMG from "../../assets/meals/breakFast.png";
import LUNCH_IMG from "../../assets/meals/lunch.png";
import DINNER_IMG from "../../assets/meals/dinner.png";
import SNACK_IMG from "../../assets/meals/snacks.png";
import GOAL_SVG from "../../assets/meal-svgs/goal.svg";
import STATUS_SVG from "../../assets/meal-svgs/bar-chart.svg";
import CHALLENGES_SVG from "../../assets/meal-svgs/challenges.svg";
import MOTIVATE_SVG from "../../assets/meal-svgs/motivation.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const dietData = [
  { image: BREAKFAST_IMG, title: "Breakfast", content: "400-500 calories" },
  { image: LUNCH_IMG, title: "Lunch", content: "450-550 calories" },
  { image: DINNER_IMG, title: "Dinner", content: "500-600 calories" },
  {
    image: SNACK_IMG,
    title: "Snacks",
    content: "150-200 calories",
  },
];

const Meals = () => {
  const [dietMeal, setDietMeal] = React.useState({});
  const [dietNeeds, setDietNeeds] = React.useState({});
  const mealPlan = useSelector((state) => state.mealState.details);

  React.useEffect(() => {
    setDietMeal(mealPlan.DietMeal);
    setDietNeeds(mealPlan.DietNeeds);
  }, [mealPlan]);

  return (
    <MobileViewLayout>
      <div className="text-center w-100 d-flex flex-column align-items-center justify-content-between courses-main pt-3">
        <div className="d-flex flex-column align-items-center">
          <div className="text-center w-100 mt-2">
            <img
              src={COURSE_IMG}
              alt=""
              width={73}
              height={70}
              className="mx-auto"
            />
            <h3
              className="mt-3 mb-0"
              style={{ fontWeight: "600", fontSize: "28px" }}
            >
              Personal Meal Plan
            </h3>
          </div>
          {Boolean(Object.keys(dietMeal).length) ||
          Boolean(Object.keys(dietNeeds).length) ? (
            <>
              <UnderStandNeeds dietNeeds={dietNeeds} />
              {dietMeal?.breakfast ||
              Object.keys(dietMeal?.breakfastDetail).length ||
              Object.keys(dietMeal?.lunchDetail).length ||
              Object.keys(dietMeal?.dinnerDetail).length ||
              dietMeal?.lunch ||
              dietMeal?.snacks ||
              dietMeal?.dinner ? (
                <DietStrategy dietMeal={dietMeal} />
              ) : (
                <>
                  <p className="pt-4 m-0" style={{ fontWeight: "bold" }}>
                    We Are Preparing Your Plan.
                  </p>
                  {dietMeal.length && (
                    <hr
                      className="w-100 mt-0"
                      style={{ backgroundColor: "#C1C1C1", height: "1px" }}
                    />
                  )}
                </>
              )}
              {typeof dietMeal?.additionalTips === "string" ||
              dietMeal?.additionalTips.length ? (
                <AdditionalTips dietMeal={dietMeal} />
              ) : (
                ""
              )}
              {!dietMeal?.additionalTips.length && (
                <hr
                  style={{ backgroundColor: "#C1C1C1", height: "1px" }}
                  className="w-100 mt-4"
                />
              )}
              <p
                className="fw-semibold py-3 main-padding-auth"
                style={{ fontSize: "16px" }}
              >
                Remember, consistency is key to achieving your goals. Stay
                motivated, focus on progress, and celebrate your successes along
                the way!
              </p>
            </>
          ) : (
            <>
              <hr className="w-100 m-0 mt-4" />
              <p
                className="py-4 m-0 w-100"
                style={{ fontWeight: "bold", backgroundColor: "#F6F6F6" }}
              >
                We Are Preparing Your Plan.
              </p>
              <hr className="w-100 m-0" />
            </>
          )}
        </div>
      </div>
    </MobileViewLayout>
  );
};

const UnderStandNeeds = ({ dietNeeds }) => {
  return (
    <div
      className="w-100 border-top border-bottom py-4 main-padding-auth d-flex flex-column gap-3 mt-3 border-diet-plan"
      style={{
        backgroundColor: "#F6F6F6",
      }}
    >
      <h4 className="m-0 fw-semibold mb-2" style={{ fontSize: "24px" }}>
        Understanding Your Needs
      </h4>
      <div className="d-flex flex-column gap-3">
        <NeedItem
          content={`${dietNeeds?.primaryGoal} and ${dietNeeds?.specificAreas}.`}
          icon={GOAL_SVG}
          title={"Goal:"}
        />
        <NeedItem
          content={`${dietNeeds.height}, ${dietNeeds.weight}, desire to reach ${dietNeeds.desiredWeight}.`}
          icon={STATUS_SVG}
          title={"Current status:"}
        />
        <NeedItem
          content={`Feeling ${dietNeeds?.flightOfStairs} climbing stairs${
            !dietNeeds?.seatedPose?.includes("I can Touch My Feet")
              ? ", difficulty touching toes"
              : ", " + dietNeeds.seatedPose
          }${
            dietNeeds?.feelStressed?.includes("Yes")
              ? ", feeling Stressed" +
                (dietNeeds.anxiousAtGym?.includes("Yes") ? " and anxious" : "")
              : dietNeeds.anxiousAtGym.includes("Yes")
              ? ", feeling anxious"
              : ""
          }${
            dietNeeds.getBored?.includes("Yes")
              ? ", getting bored with exercise"
              : ""
          }.`}
          icon={CHALLENGES_SVG}
          title={"Challenges:"}
        />
        {(dietNeeds.motivate || dietNeeds.rewardYourSelf) && (
          <NeedItem
            content={
              [dietNeeds.motivate, dietNeeds.rewardYourSelf].toString() + "."
            }
            icon={MOTIVATE_SVG}
            title={"Motivations:"}
          />
        )}
      </div>
    </div>
  );
};

const NeedItem = ({ content, icon, title }) => {
  return (
    <div className="d-flex gap-3 ">
      <img style={{ width: "24px", height: "24px" }} src={icon} alt="" />
      <div>
        <p className="m-0 fw-semibold text-start " style={{ fontSize: "14px" }}>
          {title}
        </p>
        <p className="m-0 text-start " style={{ fontSize: "14px" }}>
          {content}
        </p>
      </div>
    </div>
  );
};

const AdditionalTips = ({ dietMeal }) => {
  return (
    <div
      className="w-100 border-top border-bottom py-4 main-padding-auth d-flex flex-column gap-3 border-diet-plan mt-4"
      style={{
        backgroundColor: "#F6F6F6",
      }}
    >
      <h3 className="m-0 fw-semibold mb-1 " style={{ fontSize: "24px" }}>
        Additional Tips
      </h3>
      <div className="d-flex flex-column gap-3">
        {typeof dietMeal.additionalTips === "string" ? (
          <div>
            <p className="m-0 text-start " style={{ fontSize: "14px" }}>
              dietMeal.additionalTips
            </p>
          </div>
        ) : (
          dietMeal.additionalTips?.map(
            (data, index) =>
              data && (
                <div key={index}>
                  <p className="m-0 text-start " style={{ fontSize: "14px" }}>
                    {data}
                  </p>
                </div>
              )
          )
        )}
      </div>
    </div>
  );
};

const DietStrategy = ({ dietMeal }) => {
  return (
    <div className="w-100 main-padding-auth d-flex flex-column gap-3 mt-4">
      <h3 className="m-0 fw-semibold mb-1" style={{ fontSize: "24px" }}>
        Diet Strategy
      </h3>
      <p className="text-start m-0" style={{ fontSize: "14px" }}>
        We'll focus on a high-protein diet with balanced carbohydrates and
        healthy fats to fuel your workouts and build muscle.
      </p>
      <div className="d-flex flex-column ">
        {dietData.map((data, index) =>
          data.title !== "Snacks" ? (
            dietMeal[data.title.toLowerCase()] ||
            Object.keys(dietMeal[data.title.toLowerCase() + "Detail"])
              .length ? (
              <DietListItem
                dietMeal={dietMeal}
                key={index}
                item={data}
                showHr={index !== dietData.length - 1}
              />
            ) : (
              ""
            )
          ) : dietMeal.snacksTips.length ||
            dietMeal[data.title.toLowerCase()] ? (
            <DietListItem
              dietMeal={dietMeal}
              key={index}
              item={data}
              showHr={index !== dietData.length - 1}
            />
          ) : (
            ""
          )
        )}
      </div>
    </div>
  );
};

const DietListItem = ({ item, showHr, dietMeal }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-2 ">
          <img src={item.image} alt="" style={{ height: "43px" }} />
          <div className="d-flex flex-column justify-content-center">
            <p
              className="m-0 text-start fw-semibold "
              style={{ fontSize: "16px" }}
            >
              {item.title}
            </p>
            {dietMeal[item.title.toLowerCase()] && (
              <p
                className="m-0 text-start"
                style={{ color: "#6C6E79", fontSize: "14px" }}
              >
                {dietMeal[item.title.toLowerCase()]
                  ? dietMeal[item.title.toLowerCase()]
                  : "- calories"}
              </p>
            )}
            {item.title === "Snacks" && dietMeal.snacksTips.length ? (
              <ul className="ps-3 mt-2 meal-list">
                {typeof dietMeal.snacksTips === "string" ? (
                  <li className="text-start" style={{ fontSize: "14px" }}>
                    {dietMeal.snacksTips}
                  </li>
                ) : (
                  dietMeal.snacksTips?.map(
                    (list, index) =>
                      list && (
                        <li
                          key={index}
                          className="text-start"
                          style={{ fontSize: "14px" }}
                        >
                          {list}
                        </li>
                      )
                  )
                )}
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>

        {item.title !== "Snacks" &&
        Object.keys(dietMeal[item.title.toLowerCase() + "Detail"]).length ? (
          <div className="d-flex align-items-center ">
            <button
              className="fw-semibold py-2"
              style={{
                backgroundColor: "#2680EB",
                color: "white",
                paddingLeft: "20px",
                paddingRight: "20px",
                fontSize: "14px",
                lineHeight: "23px",
                borderRadius: "5px",
                border: "none",
                outline: "none",
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("details", {
                  state: {
                    key: item.title,
                    details: dietMeal[item.title.toLowerCase() + "Detail"],
                  },
                });
              }}
            >
              View
            </button>
          </div>
        ) : (
          ""
        )}
      </div>

      {showHr ? (
        item.title === "Dinner" &&
        !dietMeal.snacksTips.length &&
        !dietMeal.snacks ? (
          ""
        ) : (
          <hr style={{ backgroundColor: "#C1C1C1", height: "1px" }} />
        )
      ) : (
        ""
      )}
    </>
  );
};

export default Meals;
