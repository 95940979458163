import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import PREDICTION from "../../assets/part-three-prediction.png";
import "../../assets/styles/main.css";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

const PartThreeEight = () => {
  const [isVisible, setIsVisible] = useState(false);

  const new_date = moment(new Date(), "DD-MM-YYYY").add("days", 14);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  return (
    <MobileViewLayout>
      {" "}
      <>
        <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
          <div
            className="text-center main-padding d-flex flex-column"
            style={{
              minHeight: "100dvh",
              paddingTop: "45px",
              paddingBottom: "45px",
            }}
          >
            <div style={{ flex: 1 }}>
              <div className="prediction-wrapper">
                <span
                  className=" mt-4 text-center"
                  style={{ fontSize: "23px", color: "#6C6E79" }}
                >
                  We predict that you'll be
                </span>
                <div className="prediction-value">
                  <span className="prediction-weight ">75 KG</span> by{" "}
                  <span className="prediction-date ">
                    {moment(new_date).format("MMM DD")}
                  </span>
                </div>
              </div>
              <div className="prediction-img-main">
                <div className="img-div">
                  <img className="prediction-img" src={PREDICTION} alt="" />
                </div>
                <div className=" d-flex  date-container custom">
                  <p className="prediction-today">Today</p>
                  <div className="prediction">
                    <span className="prediction-today-date">
                      {moment(new_date).format("MMM DD")}
                    </span>
                    <span className="prediction-today-date">
                      {moment(new_date).format("MMM DD")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="prediction-great-main">
                <span className="prediction-great">Still on track!</span>
                <p className="prediction-text">
                  We'll incorporate your goal into your personalized plan.
                </p>
              </div>
            </div>

            <button
              className="btn common-btn-color w-100 fw-bold mt-"
              style={{ height: "70px" }}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </>
    </MobileViewLayout>
  );
};

export default PartThreeEight;
