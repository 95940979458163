import React from "react";
import { useNavigate } from "react-router-dom";
import { RoutesName } from "../utils/RoutesName";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
      <p
        className="my-2 "
        style={{
          color: "#000000",
          fontWeight: "regular",
          fontSize: "14px",
          textDecoration: "underline",
        }}
        onClick={() => {
          window.scrollTo(0, 0);
          navigate(RoutesName.terms);
        }}
      >
        Terms and Conditions
      </p>
      <p
        className="mb-2"
        style={{
          color: "#000000",
          fontWeight: "regular",
          fontSize: "14px",
          textDecoration: "underline",
        }}
        onClick={() => {
          window.scrollTo(0, 0);
          navigate(RoutesName.privacy);
        }}
      >
        Privacy Policy
      </p>
      <a
        className="mt-0"
        style={{
          color: "#000000",
          fontWeight: "regular",
          fontSize: "14px",
          textDecoration: "underline",
        }}
        href="mailto:support@eazyfit.app"
      >
        support@eazyfit.app
      </a>
      <p
        className="mt-2 mb-2"
        style={{ color: "#6C6E79", fontWeight: "regular", fontSize: "14px" }}
      >
        Copyright ® 2024. All Rights Reserved.
      </p>
    </div>
  );
};
