import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import G1 from "../../assets/img-red.png";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

export const PartFourTen = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center heading-font">
              This is how weight loss can change your facial features
            </h3>
            <img src={G1} className="w-100 mt-5" alt="" srcset="" />
          </div>
          <p className="p-0 m-0 text-partfourten">
            Losing weight can help slim down the cheeks and jawline, as well as
            tighten and firm up any saggy skin.
          </p>
          <button
            className="btn common-btn-color w-100 fw-bold mt-4"
            style={{ height: "70px" }}
            onClick={() => {
              navigate(RoutesName.partFourEleven);
            }}
          >
            Next
          </button>
        </div>
      </div>
    </MobileViewLayout>
  );
};
