export const RoutesName = {
  index: "/",

  courses: "/course",
  courseDetails: "/course/:id",
  profile: "/profile",

  meal: "/meal",
  mealDetails: "/meal/details",

  subscription: "/manage-subscription",
  login: "/login",
  otp: "/otp",
  terms: "/terms-condition",
  privacy: "/privacy-policy",
  getPlan: "/your-personal-plan",
  payment: "/register",
  partOneIntro: "/part-one",
  partOneGoal: "/",
  partOneMotivate: "/part-one/motivate",
  partOneAreas: "/part-one/areas",

  partTwoIntro: "/part-two",
  partTwoHeight: "/part-two/height",
  partTwoWeight: "/part-two/weight",
  partTwoGoalWeight: "/part-two/goal-weight",
  partTwoResult: "/part-two/result",
  partTwoPrediction: "/part-two/prediction",

  partThreeIntro: "/part-three",
  partThreeOne: "/part-three/gender",
  partThreeTwo: "/part-three/age",
  partThreeSeven: "/part-three/workout-injuries",
  partThreeFour: "/part-three/place",
  partThreeFive: "/part-three/preferred-type",
  partThreeSix: "/part-three/workout-levels",
  partThreeGoals: "/part-three/potential-goals",
  partThreePrediction: "/part-three/prediction",

  partFourIntro: "/part-four",
  partFourOne: "/part-four/seated",
  partFourTwo: "/part-four/feeling",
  partFourThree: "/part-four/suitable",
  partFourThreeNew: "/part-four/review",
  partFourFour: "/part-four/hard-boring",
  partFourFive: "/part-four/stressed",
  partFourSix: "/part-four/environment",
  partFourSeven: "/part-four/final",
  partFourEight: "/part-four/reward",
  partFourNine: "/part-four/see-yourself",
  partFourTen: "/part-four/face",
  partFourDiet: "/part-four/diet",
  partFourEleven: "/part-four/wanna-loss-weight",
  partFourTwelve: "/part-four/wanna-get-attractive",
  partFourThirteen: "/part-four/farewell",
  partFourFourteen: "/part-four/reviews",
};
