import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ACTIVE_HOME from "../../assets/active/g1761.svg";
import ACTIVE_DOUBLE from "../../assets/active/gym.svg";
import ACTIVE_NONE from "../../assets/active/none.svg";
import ACTIVE_MAT from "../../assets/active/woman-laying-down-lifting-head.svg";
import smilingFace from "../../assets/face-smiling.png";
import HOME from "../../assets/g1761.svg";
import DOUBLE from "../../assets/gym.svg";
import NONE from "../../assets/none.svg";
import MAT from "../../assets/woman-laying-down-lifting-head.svg";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";
import ApiService from "../../service/api";

export const PartThreeFive = () => {
  const [selectedIndex, setSelectedIndex] = useState([
    {
      icon: DOUBLE,
      text: "No weights",
      slogan: "Ok, we got it!",
      description:
        "We will choose workouts that suit your lifestyle with no equipment.",
      select: false,
      activeIcon: ACTIVE_DOUBLE,
    },
    {
      icon: HOME,
      text: "No Jumping",
      slogan: "Ok, we got it!",
      description:
        "We will select workouts without jumping especially for you.",
      select: false,
      activeIcon: ACTIVE_HOME,
    },
    {
      icon: MAT,
      text: "100% Lying Down Experience",
      slogan: "Ok, we got it!",
      description:
        "We will offer you a broad suite of workouts without leaving your bed.",
      select: false,
      activeIcon: ACTIVE_MAT,
    },
    {
      icon: NONE,
      text: "Not listed here",
      slogan: "Good!",
      description:
        "We have achievable programs to progressively build your fitness.",
      select: false,
      activeIcon: ACTIVE_NONE,
    },
  ]);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
    const set = selectedIndex.every((item) => item.select !== true);
    setDisabled(set);
  }, [selectedIndex]);

  React.useEffect(() => {
    if (location.state?.data) {
      const array = location.state.data.split(",");
      const updatedItems = selectedIndex.map((item) => {
        if (array.includes(item.text)) {
          return { ...item, select: true };
        } else {
          return { ...item, select: false };
        }
      });

      setSelectedIndex(updatedItems);
      setDisabled(false);
    }
  }, []);

  const updateData = (value) => {
    ApiService.updateGoals({ favoriteWorkOut: value })
      .then((res) => navigate(-1))
      .catch((err) => {
        console.log(err);
        navigate(-1);
      });
  };

  const handleNextClick = () => {
    const selectedItems = selectedIndex.filter((item) => item.select);
    const selectedItemsText = selectedItems.map((item) => item.text);
    dispatch(
      setDetailsData({
        favoriteWorkOut: selectedItemsText.toString(),
      })
    );

    location.state?.data
      ? updateData(selectedItemsText.toString())
      : navigate(RoutesName.partThreeFour);
  };

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center heading-font">
              What's your favorite workout type?
            </h3>
          </div>
          {selectedIndex.map((e, i) => (
            <Tile
              slogan={e.slogan}
              key={i}
              icon={e.icon}
              text={e.text}
              selected={e.select}
              index={i}
              setSelected={setSelectedIndex}
              list={selectedIndex}
              element={e}
            />
          ))}
          <button
            className={`btn w-100 fw-bold mt-4 ${
              location.state?.data ? "profile-button-bg" : "common-btn-color"
            }`}
            style={{ height: "70px" }}
            disabled={disabled}
            onClick={handleNextClick}
          >
            {location.state?.data ? "Save" : "Next"}
          </button>
        </div>
      </div>
    </MobileViewLayout>
  );
};

const Tile = ({
  icon,
  text,
  selected,
  index,
  setSelected,
  list,
  element,
  slogan,
}) => {
  const handleSelect = (index) => {
    if (index != 3) {
      const update = [...list];
      update[3].select = false;

      update[index].select = !update[index].select;
      setSelected([...update]);
    } else {
      let update = [...list];
      update = update.map((e) => {
        return {
          ...e,
          select: false,
        };
      });
      update[3].select = true;
      setSelected([...update]);
    }
  };
  return (
    <>
      <div
        className={`bg-white d-flex align-items-center mt-2 ${
          selected ? "active-box selected-background" : ""
        }`}
        style={{
          height: "75px",
          width: "100%",
          borderRadius: "15px",
          padding: "20px 20px",
        }}
        onClick={() => {
          handleSelect(index);
        }}
      >
        <img
          src={selected ? element.activeIcon : icon}
          alt=""
          width={32}
          height={32}
        />
        <h6 className="p-0 m-0 ms-2 text-start">{text}</h6>
      </div>
      {element?.select && (
        <div
          className="d-flex align-items-start flex-column mt-2 selected-box"
          onClick={() => {
            handleSelect(index);
          }}
        >
          <div className="d-flex flex-row align-items-center justify-content-center">
            <img src={smilingFace} alt="" width={28} height={28} />
            <h6
              className="p-0 m-0 text-start fw-bold px-2"
              style={{ fontSize: "15px" }}
            >
              {element.slogan}
            </h6>
          </div>
          <p className="p-0 m-0 text-start" style={{ fontSize: "13px" }}>
            {element.description}
          </p>
        </div>
      )}
    </>
  );
};
