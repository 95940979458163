import React, { useEffect, useState } from "react";
import COURSE_IMG from "../../assets/course-img.png";
import BACK_IMG from "../../assets/back.png";
import Vimeo from "@u-wave/react-vimeo";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../service/api";
import { showErrorToast } from "../../utils/ToastsModal";
import { Loader } from "../../widgets/Loader";
import { useDispatch } from "react-redux";
import { checkLogin } from "../../slices/AuthSlice";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

export const CourseDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [videoList, setVideoList] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchCourseVideos();
  }, []);

  useEffect(() => {
    if (videoList.length > 0 && currentVideo === null) {
      setCurrentVideo({ index: 0, obj: videoList[0] });
    }
  }, [videoList]);

  const fetchCourseVideos = async () => {
    await ApiService.getCourseVideoList(id)
      .then((res) => {
        if (res.status === 200) {
          setVideoList(res.data);
        } else if (res.status === 202) {
          setError(res.data.message);
          setTimeout(() => {
            localStorage.removeItem("token");
            dispatch(checkLogin());
          }, 6000);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        const res = JSON.parse(err?.request?.response);
        showErrorToast(res.message, 10000);
        setLoading(false);
      });
  };

  const addView = async () => {
    await ApiService.addViewVideo({
      video_id: currentVideo?.obj?.id,
    })
      .then((res) => {
        if (res.status === 201) {
          if (currentVideo.index < videoList.length) {
            const nextInd = currentVideo.index + 1;
            const updatedList = [...videoList];
            updatedList[currentVideo.index].isViewed = true;

            setVideoList(updatedList);

            setCurrentVideo({ index: nextInd, obj: videoList[nextInd] });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatedVimeoVideoId = (url) => {
    const splittedUrl = url?.split("/")[3];
    if (url.includes("https")) {
      if (!url.includes("https://player.vimeo.com/video")) {
        return `https://player.vimeo.com/video/${splittedUrl}`;
      } else {
        return url;
      }
    } else {
      return url;
    }
  };

  return (
    <MobileViewLayout>
      <div className="w-100 pt-3">
        <div className="text-center w-100" style={{ padding: "36px 0 0 0" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <img
              src={BACK_IMG}
              style={{ position: "absolute", left: 36, zIndex: 10 }}
              alt=""
              onClick={() => {
                navigate(-1);
              }}
            />
            <img
              src={COURSE_IMG}
              alt=""
              width={70}
              height={70}
              className="mx-auto"
            />
          </div>
          <h4 className="mt-2 " style={{ fontWeight: "600" }}>
            {currentVideo?.obj?.title}
          </h4>
          <h6 className="mt-1" style={{ color: "#6C6E79" }}>
            {currentVideo?.obj?.subtitle}
          </h6>
          <hr />
          <div className="p-2">
            {error ? <h6 className="mt-5 text-main px-4">{error}</h6> : <></>}
            {loading ? (
              <Loader />
            ) : (
              <>
                {currentVideo !== null ? (
                  <Vimeo
                    video={updatedVimeoVideoId(
                      currentVideo?.obj?.vimeo_video_id
                    )}
                    className="w-100 m-0"
                    width={"100%"}
                    autoplay={true}
                    loop={false}
                    responsive={true}
                    onEnd={() => addView()}
                  />
                ) : (
                  <Loader />
                )}

                <hr />
                {videoList.map((e, index) => {
                  return (
                    <ListTile
                      e={e}
                      key={e.id}
                      index={index}
                      currentVideo={currentVideo}
                      setCurrentVideo={setCurrentVideo}
                      videoList={videoList}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </MobileViewLayout>
  );
};

const ListTile = ({ e, index, currentVideo, setCurrentVideo, videoList }) => {
  const handleClick = () => {
    setCurrentVideo({ index: index, obj: videoList[index] });
  };

  return (
    <div
      className="text-start mt-2"
      style={{ padding: "10px 36px", cursor: "pointer" }}
      onClick={handleClick}
    >
      <div className="d-flex align-items-start">
        <h3
          className={`me-3 rounded-circle p-3 text-center d-flex align-items-center justify-content-center ${
            index === currentVideo?.index && "text-white bg-main"
          }`}
          style={{
            height: "35px",
            width: "35px",
            backgroundColor: index !== currentVideo?.index && "#EFF0F4",
            color: index !== currentVideo?.index && "#C1C1C1",
          }}
        >
          {index + 1}
        </h3>
        <div>
          <h5
            className="m-0 color text-main"
            style={{
              fontWeight: "600",
              color: index !== currentVideo?.index && "#C1C1C1",
            }}
          >
            {e.title}
          </h5>
          <h6
            className="m-0 mt-1"
            style={{
              color: index !== currentVideo?.index ? "#C1C1C1" : "#6C6E79",
            }}
          >
            {e.subtitle}
          </h6>
        </div>
      </div>
      <hr />
    </div>
  );
};
