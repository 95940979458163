import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import G2 from "../../assets/cross.svg";
import G1 from "../../assets/green-tick.svg";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";

export const PartFourSix = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [selected, setSelected] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  const nextPageRoute = () => {
    dispatch(setDetailsData({ anxiousAtGym: selected === 1 ? "No" : "Yes" }));
    setTimeout(() => {
      navigate(RoutesName.partFourSeven);
    }, 200);
  };

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center heading-font ">
              Do you relate to the statement below?
            </h3>
            <div className="mt-5 p-1"></div>
            <div
              className="w-100 p-2 rounded-3 bg-white my-5 "
              style={{ color: "#000000" }}
            >
              I feel uncomfortable and anxious at the gym. I want to work out in
              a place I feel good and relaxed.
            </div>
            <div className="mt-5 p-1"></div>

            <div className="row g-0 mt-5">
              <div className="col-6 pe-1">
                <div
                  className={`bg-white shadow p-5 rounded-3 text-center ${
                    selected === 1 ? "border-main selected-background" : ""
                  }`}
                  onClick={() => {
                    setSelected(1);
                    nextPageRoute();
                  }}
                >
                  <img src={G2} width={28} height={28} alt="" srcset="" />
                  <h6 className="m-0 mt-2 fw-bold">No</h6>
                </div>
              </div>
              <div className="col-6 ps-1">
                <div
                  className={`bg-white shadow p-5 rounded-3 text-center ${
                    selected === 2 ? "border-main selected-background" : ""
                  }`}
                  onClick={() => {
                    setSelected(2);
                    nextPageRoute();
                  }}
                >
                  <img src={G1} width={28} height={28} alt="" srcset="" />
                  <h6 className="m-0 mt-2 fw-bold">Yes</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MobileViewLayout>
  );
};
