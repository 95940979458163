/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import BOT from "../../assets/bot.gif";
import ApiService from "../../service/api";
import { checkLogin } from "../../slices/AuthSlice";
import { RoutesName } from "../../utils/RoutesName";
import { showErrorToast, showSuccessToast } from "../../utils/ToastsModal";
import { Footer } from "../../widgets/Footer";
import { Loader } from "../../widgets/Loader";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

export const Otp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(localStorage.getItem("email") ?? "");
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const { details } = useSelector((state) => state.detailState);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  const handleVerification = async () => {
    setLoading(true);
    await ApiService.verifyOTP({ email: email.toLocaleLowerCase(), otp: otp })
      .then(async (response) => {
        if (response.status === 200) {
          localStorage.setItem("token", JSON.stringify(response.data));
          if (location.state) {
            location.state === "NewRegistered" &&
              (await ApiService.updateMealPlanData(details).catch(() => {}));
          }
          await dispatch(checkLogin());
          setTimeout(() => {
            navigate(RoutesName.courses);
          }, 1000);
        }
        setLoading(false);
      })
      .catch((error) => {
        showErrorToast(error?.request?.response);
        setLoading(false);
      });
  };

  const resendOTP = async () => {
    setOtp("");
    setLoading(true);
    await ApiService.loginUser({ email: email.toLocaleLowerCase() })
      .then((response) => {
        if (response.status === 200) {
          showSuccessToast(response.data?.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        showErrorToast(error?.request?.response);
        setLoading(false);
      });
  };

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column pb-4"
          style={{ minHeight: "100dvh" }}
        >
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-center text-center align-items-center"
          >
            <img src={BOT} width={122} alt="" />
            <h3 className="mt-3 fw-bold" style={{ fontSize: "28px" }}>
              Enter OTP
            </h3>

            <h6
              style={{ fontSize: "14px" }}
              className="text-center fw-lighter mb-5"
            >
              A one-time password has been sent to your <br />
              Email: {email}
            </h6>
            <OtpInput
              inputStyle={{
                width: "72px",
                height: "72px",
                textAlign: "center",
                borderRadius: "6px",
                boxShadow: "none",
                color: "black",
                backgroundColor: "transparent",
                borderColor: "#6A6C84",
                border: "1px solid #6A6C84",
                margin: "0px 5px",
              }}
              placeholder={0}
              inputType="tel"
              value={otp}
              renderSeparator={""}
              onChange={setOtp}
              numInputs={4}
              renderInput={(props) => <input {...props} />}
            />
            {loading ? (
              <Loader />
            ) : (
              <>
                <button
                  onClick={() => handleVerification()}
                  className="w-100 main-button main-background py-3 fw-medium text-white mt-4"
                  disabled={otp.length !== 4}
                >
                  Submit
                </button>
                <p
                  className="mt-4 "
                  style={{
                    color: "#6C6E79",
                    fontWeight: "regular",
                    fontSize: "14px",
                  }}
                >
                  <span
                    className="main-text text-decoration-underline"
                    onClick={() => resendOTP()}
                  >
                    Resend OTP
                  </span>
                </p>
              </>
            )}
          </div>

          <Footer />
        </div>
      </div>
    </MobileViewLayout>
  );
};
