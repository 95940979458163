import { useState } from "react";
import { useEffect } from "react";

const TimerNotification = () => {
  const [timeLeft, setTimeLeft] = useState(900);
  const [visible, setVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  const handleGetPlan = () => {
    const element = document.getElementById("get-plan-scroll");
    const element2 = document.getElementById("get-plan-scroll-new");
    windowWidth < 400 ? element2.scrollIntoView() : element.scrollIntoView();
  };

  useEffect(() => {
    const { innerWidth: width } = window;
    setWindowWidth(width);
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((timeLeft) => {
        if (timeLeft === 1) {
          return 900;
        } else {
          return timeLeft - 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div className={`timer-notification  ${visible ? "animate" : ""}`}>
      <div>
        <h6 className="fw-bold m-0" style={{ fontSize: "20px" }}>
          00 : {minutes < 10 ? ` 0${minutes} ` : ` ${minutes}`}:
          {seconds < 10 ? ` 0${seconds} ` : ` ${seconds}`}
        </h6>
        <h6
          className="thin-text m-0 text-light text-start"
          style={{ flex: "1", fontSize: "12px" }}
        >
          Your personalized plan has been <br /> reserved for the next 15
          minutes!
        </h6>
      </div>
      <button className="get-plan-btn" onClick={handleGetPlan}>
        GET MY PLAN
      </button>
    </div>
  );
};

export default TimerNotification;
