import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DIET_ICON from "../../assets/diet-icon.svg";
import Face_ICON from "../../assets/face.png";
import Heart_ICON from "../../assets/heart.svg";
import Like_ICON from "../../assets/like.png";
import Star_ICON from "../../assets/star.png";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";

export const PartOneScreenOne = () => {
  const [selectedIndex, setSelectedIndex] = useState([
    {
      icon: DIET_ICON,
      text: "Get in  Shape",
      select: false,
    },
    {
      icon: Star_ICON,
      text: "Look Your Best",
      select: false,
    },
    {
      icon: Face_ICON,
      text: "Get Summer-Ready",
      select: false,
    },
    {
      icon: Like_ICON,
      text: "Boost Your Self-confidence",
      select: false,
    },
    {
      icon: Heart_ICON,
      text: "Learn to Celebrate Yourself",
      select: false,
    },
  ]);

  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  return (
    <MobileViewLayout>
      <div className={`animate-from-right  ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center heading-font">
              What drives you the most?
            </h3>
          </div>
          {selectedIndex.map((e, i) => (
            <Tile
              key={i}
              icon={e.icon}
              text={e.text}
              selected={e.select}
              index={i}
              setSelected={setSelectedIndex}
              list={selectedIndex}
              navigate={navigate}
            />
          ))}
        </div>
      </div>
    </MobileViewLayout>
  );
};

const Tile = ({ icon, text, selected, index, setSelected, list, navigate }) => {
  const dispatch = useDispatch();
  const handleSelect = (index) => {
    setTimeout(() => {
      navigate(RoutesName.partTwoIntro);
    }, 200);
    const selectedItem = list[index];
    dispatch(setDetailsData({ motivate: selectedItem.text }));
    const update = [...list];
    update[index].select = !update[index].select;
    setSelected([...update]);
  };
  return (
    <div
      className={`bg-white d-flex align-items-center mt-3 ${
        selected ? "border-main selected-background" : ""
      }`}
      style={{
        height: "75px",
        width: "100%",
        borderRadius: "15px",
        padding: "20px 20px",
      }}
      onClick={() => {
        handleSelect(index);
      }}
    >
      <img src={icon} alt="" width={32} height={32} />
      <h6 className="ms-3 text-start mb-0">{text}</h6>
    </div>
  );
};
