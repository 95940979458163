import React, { useEffect, useState } from "react";
import TICK from "../../assets/tick.png";
import LADY from "../../assets/character-interact/character.png";
import BELLY from "../../assets/character-interact/Flat_Belly.png";
import ROUND_BUT from "../../assets/character-interact/Round_Butt.png";
import FULL_BODY from "../../assets/character-interact/full_character.png";
import SLIM_LEGS from "../../assets/character-interact/Slim_legs.png";
import TONED_ARMS from "../../assets/character-interact/Toned_Arms.png";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";
import ApiService from "../../service/api";

export const PartOneScreenThird = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [showImage, setShowImage] = useState("fullBody");
  const [items, setItems] = useState([
    { text: "Well-toned Arms", label: "arms", selected: false },
    { text: "Sleek Belly", label: "belly", selected: false },
    { text: "Sculpted Butt", label: "butt", selected: false },
    { text: "Slim Legs", label: "legs", selected: false },
    { text: "Full Body", label: "fullBody", selected: true },
  ]);
  const [isVisible, setIsVisible] = useState(false);

  const handleItemClick = (index, element) => {
    const updatedItems = items.map((item) => {
      if (item.label === element.label) {
        return { ...item, selected: !element.selected };
      } else {
        return { ...item, selected: false };
      }
    });
    setItems(updatedItems);
    const newSelected = updatedItems.find((d) => d.selected === true);
    setShowImage(newSelected?.label);
  };

  const updateData = (value) => {
    ApiService.updateGoals({ specificAreas: value })
      .then((res) => navigate(-1))
      .catch((err) => {
        console.log(err);
        navigate(-1);
      });
  };

  const handleNextClick = () => {
    const selectedItem = items.find((item) => item.selected === true);
    dispatch(setDetailsData({ specificAreas: selectedItem.text }));
    location?.state?.data
      ? updateData(selectedItem.text)
      : navigate(RoutesName.partOneMotivate);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 50);

    if (location.state?.data) {
      const selectedItem = items.find(
        (item) => item.text === location.state.data
      );
      if (selectedItem) {
        const updatedItems = items.map((item) => {
          if (item?.label === selectedItem.label) {
            return { ...item, selected: true };
          } else {
            return { ...item, selected: false };
          }
        });
        setItems(updatedItems);
        setShowImage(selectedItem.label);
      }
    }
  }, []);

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column bg-light body-content"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center heading-font">
              Any specific areas you'd like to work on?
            </h3>
          </div>

          <div>
            <div
              className="row align-items-center"
              style={{ marginBottom: "39px", marginLeft: "auto" }}
            >
              <div className="col-6 p-0 pe-2">
                {items.map((e, i) => (
                  <Tile
                    key={i}
                    text={e.text}
                    selected={e.selected}
                    index={i}
                    handleClick={() => handleItemClick(i, e)}
                  />
                ))}
              </div>
              <div
                className="col-6 m-0 p-0"
                style={{ position: "relative", height: "100%" }}
              >
                <img
                  src={LADY}
                  className="m-0 p-0"
                  alt=""
                  style={{ height: "60svh" }}
                />
                {showImage === "arms" ? (
                  <img
                    src={TONED_ARMS}
                    alt=""
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      margin: "auto",
                      zIndex: 3,
                      height: "60svh",
                    }}
                  />
                ) : null}
                {showImage === "belly" ? (
                  <img
                    src={BELLY}
                    alt=""
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      margin: "auto",
                      zIndex: 1,
                      height: "60svh",
                    }}
                  />
                ) : null}

                {showImage === "butt" ? (
                  <img
                    src={ROUND_BUT}
                    alt=""
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      margin: "auto",
                      zIndex: 2,
                      height: "60svh",
                    }}
                  />
                ) : null}
                {showImage === "legs" ? (
                  <img
                    src={SLIM_LEGS}
                    alt=""
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      margin: "auto",
                      zIndex: 4,
                      height: "60svh",
                    }}
                  />
                ) : null}
                {showImage === "fullBody" ? (
                  <img
                    src={FULL_BODY}
                    alt=""
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      margin: "auto",
                      zIndex: 4,
                      height: "60svh",
                    }}
                  />
                ) : null}
              </div>
            </div>
            <button
              className={`btn w-100 fw-bold ${
                location.state?.data ? "profile-button-bg" : "common-btn-color"
              }`}
              style={{
                height: "70px",
              }}
              onClick={handleNextClick}
            >
              {location.state?.data ? "Save" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </MobileViewLayout>
  );
};

const Tile = ({ text, selected, index, handleClick }) => {
  return (
    <div
      className={`bg-white d-flex align-items-center mt-3 align-items-center py-2 ${
        selected ? "border-main selected-background" : ""
      }`}
      style={{
        minHeight: "50px",
        width: "100%",
        borderRadius: "15px",
        padding: "0px 10px",
      }}
      onClick={() => handleClick(index)}
    >
      {selected ? (
        <div
          className="d-flex align-items-center text-center justify-content-center p-0"
          style={{
            height: "14px",
            width: "16px",
            backgroundColor: "#2680EB",
            borderRadius: "50%",
            color: "#2680EB",
          }}
        >
          <img src={TICK} alt="" width={8} height={7} srcSet="" />
        </div>
      ) : (
        <div
          className="d-flex align-items-center text-center justify-content-center"
          style={{
            height: "14px",
            width: "16px",
            backgroundColor: "white",
            borderWidth: "1px",
            borderColor: "#6C6E79",
            borderStyle: "solid",
            borderRadius: "50%",
          }}
        ></div>
      )}

      <div className="w-100">
        <h6 className="text-start m-0 ms-2 p-0" style={{ fontSize: "14px" }}>
          {text}
        </h6>
      </div>
    </div>
  );
};
