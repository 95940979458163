import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";
import ApiService from "../../service/api";

export const PartTwoScreenThree = ({ bodyDetails, setBodyDetails }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const [placeholderVisible, setPlaceholderVisible] = useState(true);
  const [ratio, setRatio] = useState(0);
  const [isLoss, setIsLoss] = useState(true);

  const weightRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  useEffect(() => {
    bodyDetails?.testData?.current?.unit === "KG" && setIsKG(true);
  }, []);

  const handleInputFocus = () => {
    setPlaceholderVisible(false);
  };

  const handleInputBlur = () => {
    setPlaceholderVisible(true);
  };

  const [number, setNumber] = useState();
  const [isKG, setIsKG] = useState(false);
  const [kgValue, setKgValue] = useState();

  useEffect(() => {
    if (isKG) {
      setNumber(Math.round(number * 0.45359237));
      setKgValue(Math.round(number * 0.45359237));
    } else {
      setNumber(Math.round(number / 0.45359237));
      setKgValue(Math.round(number / 0.45359237));
    }
  }, [isKG]);

  useEffect(() => {
    let kg = isKG ? number : Math.round(number * 0.45359237);
    let weight = isLoss
      ? Math.round(bodyDetails.currentWeight - kg)
      : kg - Math.round(bodyDetails.currentWeight);
    setRatio((weight / Math.round(bodyDetails.currentWeight)) * 100);
    setIsLoss(bodyDetails.currentWeight > kg);
  }, [number, isLoss, bodyDetails.currentWeight, bodyDetails.goalWeight]);

  const inputLength = number && !isNaN(number) ? String(number)?.length : 1;

  useEffect(() => {
    if (location.state?.data) {
      if (location.state.data.includes("kg")) {
        setKgValue(
          Math.round(location.state?.data.replace(/\D/g, "") / 0.45359237)
        );
        setNumber(
          Math.round(location.state?.data.replace(/\D/g, "") / 0.45359237)
        );
        setIsKG(true);
      } else {
        setIsKG(false);
        setKgValue(location.state.data.replace(/\D/g, ""));
        setNumber(location.state.data.replace(/\D/g, ""));
      }
    }
  }, []);

  const updateData = (value) => {
    ApiService.updateGoals({ desiredWeight: value })
      .then((res) => navigate(-1))
      .catch((err) => {
        console.log(err);
        navigate(-1);
      });
  };

  const handleNextClick = () => {
    setBodyDetails({
      ...bodyDetails,
      goalWeight: !isKG
        ? Math.round(isNaN(kgValue) ? 0 : kgValue * 0.45359237)
        : Math.round(isNaN(kgValue) ? 0 : kgValue),
      testData: {
        ...bodyDetails.testData,
        desire: {
          weight: number,
          unit: isKG ? "KG" : "LBS",
        },
      },
    });

    if (kgValue || !isNaN(kgValue)) {
      dispatch(
        setDetailsData({
          desiredWeight: !isKG ? `${kgValue} lbs` : `${kgValue} kg`,
        })
      );
      location.state?.data
        ? updateData(!isKG ? `${kgValue} lbs` : `${kgValue} kg`)
        : navigate(RoutesName.partTwoResult);
    }
  };

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "15px",
            paddingBottom: "45px",
          }}
        >
          <div style={{}}>
            <h3 className="fw-bold mt-4 text-center">Your desired weight</h3>
            <div className="mt-4 d-flex justify-content-center">
              <div className="p-0 border new-border rounded-5 d-flex">
                <h5
                  onClick={() => setIsKG(false)}
                  className={
                    isKG
                      ? "px-3 m-0 py-1"
                      : "new-color rounded-5 px-4 m-0 text-white py-1"
                  }
                >
                  lbs
                </h5>
                <h5
                  onClick={() => setIsKG(true)}
                  className={
                    !isKG
                      ? "px-3 m-0 py-1"
                      : "new-color rounded-5 px-4 m-0 text-white py-1"
                  }
                >
                  kg
                </h5>
              </div>
            </div>
            <div
              className="mt-1 mb-1"
              style={{
                position: "relative",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="d-flex flex-row align-items-center justify-content-center">
                <input
                  placeholder={placeholderVisible ? "0" : ""}
                  ref={weightRef}
                  maxLength={3}
                  step="0.1"
                  type="text"
                  className="my-auto"
                  value={number && number !== "NaN" ? number : ""}
                  onChange={(e) => {
                    setKgValue(e.target.value);
                    setNumber(e.target.value);
                  }}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  style={{
                    height: "auto",
                    fontWeight: "700",
                    border: "none",
                    backgroundColor: "rgb(247, 247, 247)",
                    fontSize: "50px",
                    outline: "none",
                    padding: "0px",
                    paddingRight: 0,
                    textAlign: "right",
                    width: `calc(${inputLength}ch`,
                    maxWidth: "99%",
                  }}
                  name=""
                  id=""
                  inputMode="numeric"
                />
                <h5
                  className="fw-bold custom-cm-input"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginTop: "20px",
                    marginBottom: "0px",
                    lineHeight: "100px",
                  }}
                >
                  {isKG ? "kg" : "lbs"}
                </h5>
              </div>
              <div
                className="d-flex align-items-end"
                style={{ overflowX: "hidden" }}
              ></div>
            </div>
          </div>

          <div className="w-100 rounded-3 mb-2 mt-0 bg-white px-3 py-2 text-start ">
            <h6 className="fw-bold m-0" style={{ color: "#009B24" }}>
              Like a breeze
            </h6>
            <h6 className="text-dark fw-bold m-0">
              {ratio == 0
                ? `Keeping your weight is a great goal.
            Let's hit it!`
                : isLoss
                ? `You will loose ${
                    ratio ? ratio.toFixed(0) : "0"
                  }% of your weight`
                : `You will gain ${
                    ratio ? ratio.toFixed(0) : "0"
                  }% of your weight`}
            </h6>
            <p style={{ fontSize: "14px" }} className="m-0 p-0 text-secondary">
              A balanced and gentle weight gain can also make a real difference.
            </p>
          </div>
          <button
            className={`btn w-100 fw-bold ${
              location.state?.data ? "profile-button-bg" : "common-btn-color"
            }`}
            style={{ height: "70px" }}
            onClick={handleNextClick}
          >
            {location.state?.data ? "Save" : "Next"}
          </button>
        </div>
      </div>
    </MobileViewLayout>
  );
};
