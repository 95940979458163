import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import COURSE_IMG from "../../assets/course-img.png";
import ApiService from "../../service/api";
import { checkLogin } from "../../slices/AuthSlice";
import { RoutesName } from "../../utils/RoutesName";
import { showErrorToast, showSuccessToast } from "../../utils/ToastsModal";
import { Footer } from "../../widgets/Footer";
import { Loader } from "../../widgets/Loader";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { toast } from "react-toastify";

export const Courses = () => {
  const [courseList, setCourseList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    console.log("useEffect triggered at:", new Date().toISOString());
    fetchCourses();
  }, []);

  useEffect(() => {
    if (location.state?.showToast) {
      toast.success("New Meal Plan will be updated soon.", {
        position: "top-center",
        autoClose: 3000,
        theme: "light",
        progress: undefined,
      });
      window.history.replaceState({}, "");
    }
  }, []);

  const fetchCourses = async () => {
    await ApiService.getCourseList()
      .then((res) => {
        if (res.status === 200) {
          setCourseList([...res.data]);
        } else if (res.status === 202) {
          setError(res.data.message);
          setTimeout(() => {
            localStorage.removeItem("token");
            dispatch(checkLogin());
          }, 6000);
        }
        setLoading(false);
      })
      .catch((err) => {
        const res = JSON.parse(err?.request?.response);
        showErrorToast(res.message, 5000);
        setLoading(false);
      });
  };
  return (
    <>
      <MobileViewLayout>
        <div className="text-center w-100 d-flex flex-column align-items-center justify-content-between courses-main pt-3">
          <div className="d-flex flex-column align-items-center">
            <div className="text-center w-100 mt-2">
              <img
                src={COURSE_IMG}
                alt=""
                width={73}
                height={70}
                className="mx-auto"
              />
              <h3
                className="mt-3"
                style={{ fontWeight: "600", fontSize: "28px" }}
              >
                All Courses
              </h3>
            </div>
            <div className="text-center course-list">
              {error ? <h6 className="mt-5 text-main px-4">{error}</h6> : <></>}
              {loading ? <Loader /> : <></>}
              {courseList.map((e, index) => {
                return (
                  <ListTile e={e} index={index} length={courseList.length} />
                );
              })}
            </div>
          </div>
        </div>
      </MobileViewLayout>
      <div className="text-center mb-3 w-100">
        <hr />
        <Footer />
      </div>
    </>
  );
};

const ListTile = ({ e, index, length }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`${RoutesName.courses}/${e.id}`);
  };
  return (
    <div
      className="text-start"
      style={{ padding: "0 36px", cursor: "pointer" }}
      onClick={handleClick}
    >
      <h5 className="m-0 " style={{ fontWeight: "600" }}>
        {e.title}
      </h5>
      <h6 className="m-0 mt-2" style={{ color: "#6C6E79" }}>
        {e.subtitle}
      </h6>
      {index !== length - 1 && <hr />}
    </div>
  );
};
