import { toast } from "react-toastify";

export const showSuccessToast = (message, time = 5000) => {
  toast.success(message, {
    position: "bottom-center",
    autoClose: time,
    theme: "light",
    progress: undefined,
  });
};
export const showErrorToast = (message, time = 5000) => {
  toast.error(message, {
    position: "bottom-center",
    autoClose: time,
    theme: "light",
    progress: undefined,
  });
};
