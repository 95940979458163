import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";

export const PartTwoScreenOne = ({ bodyDetails, setBodyDetails }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [placeholderVisible, setPlaceholderVisible] = useState(true);
  const [pointPlaceholderVisible, setPointPlaceholderVisible] = useState(true);
  const dispatch = useDispatch();

  const feetRef = useRef(null);
  const inchRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  const [number, setNumber] = useState();
  const [point, setPoint] = useState();
  const [showError, setShowError] = useState(false);
  const [isCM, setIsCM] = useState(false);

  useEffect(() => {
    if (isCM) {
      setNumber(
        (
          parseInt(point === "NaN" ? "0" : point) * 2.54 +
          parseInt(number) * 30.48
        ).toFixed(0)
      );
    } else {
      let number1 = parseInt(number);
      let inch = number1 * 0.393701;
      setNumber(parseInt(inch / 12));
      setPoint(((inch / 12 - parseInt(inch / 12)) * 12).toFixed(0));
    }
  }, [isCM]);

  const handleInputFocus = () => {
    setPlaceholderVisible(false);
  };

  const handleInputBlur = () => {
    setPlaceholderVisible(true);
  };

  const handlePointInputFocus = () => {
    setPointPlaceholderVisible(false);
  };

  const handlePointInputBlur = () => {
    setPointPlaceholderVisible(true);
  };

  const pointLength = point && !isNaN(point) ? point?.length : 1;
  const cmLength = number && !isNaN(number) ? number?.length : 1;

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "15px",
            paddingBottom: "45px",
          }}
        >
          <div>
            <h3 className="fw-medium mt-4 text-center heading-font">
              Your height?
            </h3>
            <div className="mt-4 d-flex justify-content-center">
              <div className=" p-0 border new-border rounded-5 d-flex">
                <h5
                  onClick={() => {
                    setIsCM(false);
                  }}
                  className={
                    isCM
                      ? "px-3 m-0 py-1"
                      : "new-color rounded-5 px-4 m-0 text-white py-1"
                  }
                >
                  ft
                </h5>
                <h5
                  onClick={() => {
                    setIsCM(true);
                  }}
                  className={
                    !isCM
                      ? "px-3 m-0 py-1"
                      : "new-color rounded-5 px-4 m-0 text-white py-1"
                  }
                >
                  cm
                </h5>
              </div>
            </div>
          </div>
          <div
            className="mt-2"
            style={{
              position: "relative",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="d-flex align-items-center flex-column" style={{}}>
              <div className="d-flex flex-row">
                <input
                  ref={feetRef}
                  placeholder={placeholderVisible ? "0" : ""}
                  step="0.1"
                  type="text"
                  value={number && number !== "NaN" ? number : ""}
                  onKeyUp={(e) => {
                    if (isCM) {
                      if (e.key === "Enter") {
                      }
                    } else if (
                      !isCM &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete"
                    ) {
                      inchRef.current.focus();
                      setNumber(e.target.value[0]);
                    }
                  }}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  onChange={(e) => {
                    if (!isCM && (e.target.value > 7 || e.target.value < 4)) {
                      setShowError(true);
                    } else {
                      setShowError(false);
                    }
                    if (e.target.value[0] !== ".") {
                      setNumber(
                        e.target.value === ""
                          ? ""
                          : e.target.value
                              .replace(
                                /([^\.]+)\.(.*)/,
                                (match, group1, group2) =>
                                  group1 + "." + group2.replace(/\./g, "")
                              )
                              .replace(/[^0-9.]/g, "")
                      );
                    } else {
                      setNumber("");
                    }
                  }}
                  className={`my-auto  ${isCM && "cm-input"} `}
                  style={{
                    height: "auto",
                    fontWeight: "700",
                    width: isCM ? `calc(${cmLength}ch` : "",
                    border: "none",
                    backgroundColor: "rgb(247, 247, 247)",
                    fontSize: "50px",
                    outline: "none",
                    padding: "0px",
                    paddingRight: 0,
                    maxWidth: isCM ? "99.99%" : "85px",
                    textAlign: "right",
                  }}
                  name=""
                  id=""
                  maxLength={isCM ? 3 : 1}
                  max={7}
                  inputMode="numeric"
                />
                <h4
                  className={`fw-600 ${
                    !isCM ? "custom-foot-input" : "custom-cm-input"
                  }`}
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginTop: "20px",
                    marginBottom: "0px",
                    lineHeight: "100px",
                  }}
                >
                  {isCM ? "cm" : "ft"}
                </h4>
                {!isCM ? (
                  <>
                    <input
                      ref={inchRef}
                      placeholder={pointPlaceholderVisible ? "0" : ""}
                      step="0.1"
                      type="text"
                      value={point && point !== "NaN" ? point : ""}
                      onKeyUp={(e) => {
                        if (e.key === "ArrowLeft") {
                          feetRef.current.focus();
                        }
                        if (e.key === "Enter") {
                        }
                      }}
                      onChange={(e) => {
                        if (Number(e.target.value) < 12) {
                          if (e.target.value == "") {
                            feetRef.current.focus();
                          }
                          setShowError(false);
                          setPoint(
                            e.target.value == ""
                              ? ""
                              : e.target.value.replace(".", "")
                          );
                        } else {
                          setShowError(true);
                        }
                      }}
                      onFocus={handlePointInputFocus}
                      onBlur={handlePointInputBlur}
                      className="my-auto"
                      style={{
                        height: "auto",
                        fontWeight: "700",
                        maxWidth: "99.99%",
                        width:
                          point === "10"
                            ? `calc(${pointLength}ch - 15px`
                            : point === "11"
                            ? `calc(${pointLength}ch - 26px)`
                            : point === "01"
                            ? `calc(${pointLength}ch - 15px)`
                            : point === "1"
                            ? `calc(${pointLength}ch - 15px)`
                            : `calc(${pointLength}ch`,
                        border: "none",
                        backgroundColor: "rgb(247, 247, 247)",
                        fontSize: "50px",
                        outline: "none",
                        padding: "0px",
                        paddingRight: 0,
                        paddingLeft: 0,
                        marginLeft: "10px",
                        textAlign: "right",
                      }}
                      name=""
                      id=""
                      maxLength={2}
                      max={11}
                      inputMode="numeric"
                    />
                    <h4
                      className=" fw-600"
                      style={{
                        fontSize: "20px",
                        marginTop: "20px",
                        fontWeight: "bold",
                        marginBottom: "0px",
                        lineHeight: "100px",
                        marginLeft: "3px",
                        marginRight: "50px",
                      }}
                    >
                      {isCM ? "cm" : "in"}
                    </h4>
                  </>
                ) : (
                  ""
                )}
              </div>
              {!isCM && showError && (
                <p
                  className="ms-4 mt-0 mb-0 p-0"
                  style={{ color: "rgb(222, 46, 68)", fontSize: "12px" }}
                >
                  Enter a value from 4ft to 7ft 11in
                </p>
              )}
            </div>
          </div>
          <button
            className="btn btn-dark w-100 mt-2 fw-bold font-weight-600"
            style={{ height: "70px" }}
            onClick={() => {
              !number && setShowError(true);
              setBodyDetails({
                ...bodyDetails,
                height: isCM
                  ? parseInt(number)
                  : (
                      parseInt(isNaN(point) ? 0 : point) * 2.54 +
                      parseInt(number) * 30.48
                    ).toFixed(0),
              });

              if (number && !showError) {
                dispatch(
                  setDetailsData({
                    height: !isCM ? `${number} ft ${point} in` : `${number} cm`,
                  })
                );
                number && !showError && navigate(RoutesName.partTwoWeight);
              }
            }}
          >
            {" "}
            Next
          </button>
        </div>
      </div>
    </MobileViewLayout>
  );
};
