import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../../service/api";
import { RoutesName } from "../../utils/RoutesName";
import { showErrorToast, showSuccessToast } from "../../utils/ToastsModal";
import { generateRandomCode } from "../../utils/modules";

const PayPalElement = ({ email, plan }) => {
  const planIdRef = useRef(`${plan}`);
  const navigate = useNavigate();

  const initialOptions = {
    "client-id": `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
    "enable-funding": "",
    "disable-funding": "card",
    vault: "true",
  };

  const createSubscription = (data, actions) => {
    const planId = planIdRef.current;
    return actions.subscription
      .create({
        plan_id: planId,
      })
      .then((orderID) => {
        return orderID;
      });
  };

  useEffect(() => {
    planIdRef.current = `${plan}`;
  }, [plan]);

  const onApprove = async (data, actions) => {
    const subscriptionID = data.subscriptionID;
    const code = generateRandomCode();
    try {
      await ApiService.createSubscription({
        email: email.toLowerCase(),
        code: code,
        payment_type: "paypal",
        subscription_id: subscriptionID,
      })
        .then(async (response) => {
          if (response.status === 200) {
            await ApiService.loginUser({
              email: email.toLowerCase(),
            })
              .then((res) => {
                if (res.status === 200) {
                  ApiService.removeTag("4462802", {
                    api_secret: process.env.REACT_APP_CK_SECRET_KEY,
                    email: email.toLowerCase(),
                  }).then(() => {
                    ApiService.addTag("4462715", {
                      api_secret: process.env.REACT_APP_CK_SECRET_KEY,
                      email: email.toLowerCase(),
                    });
                  });
                  localStorage.setItem("email", email.toLowerCase());
                  showSuccessToast(res?.data?.message);
                  navigate(RoutesName.otp,{
                    state: "NewRegistered",
                  });
                }
              })
              .catch((err) => {
                showErrorToast(err?.response?.data);
              });
          }
        })
        .catch((error) => {
          showErrorToast(
            error?.response?.data?.error ??
              error?.response?.data?.message ??
              error?.response?.data ??
              "Something went wrong"
          );
        });
    } catch (e) {
      console.log(e.err);
    }
  };

  const onError = (err) => {
    console.log("🚀  err:", err);
    showErrorToast("Payment rejected");
  };

  const onCancel = () => {
    showErrorToast("Payment rejected");
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className="mt-3" style={{ height: "55px" }}>
        <PayPalButtons
          style={{ layout: "vertical", label: "buynow", height: 55 }}
          createSubscription={createSubscription}
          onApprove={onApprove}
          catchError={onError}
          onError={onError}
          onCancel={onCancel}
        />
      </div>
    </PayPalScriptProvider>
  );
};

export default PayPalElement;
