import React, { useEffect, useState } from "react";
import G from "../../assets/g.svg";
import G1 from "../../assets/g1.svg";
import G2 from "../../assets/g2.svg";
import { useNavigate } from "react-router-dom";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";

export const PartFourOne = () => {
  const [selectedIndex, setSelectedIndex] = useState([
    {
      icon: G,
      text: "Far from My Feet",
      slogan: "",
      select: false,
    },
    {
      icon: G1,
      text: "Close to My Feet",
      slogan: "",
      select: false,
    },
    {
      icon: G2,
      text: "I can Touch My Feet",
      slogan: "",
      select: false,
    },
  ]);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
    const set = selectedIndex.every((item) => item.select !== true);
    setDisabled(set);
  }, [selectedIndex]);

  return (
    <MobileViewLayout>
      {" "}
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center heading-font">
              What's your range of motion in a seated forward bend pose ?
            </h3>
            <p className="mt-4" style={{ color: "gray", fontSize: "12px" }}>
              According to the Physical Activity Guidelines, this will help test
              your flexibility.
            </p>
          </div>
          {selectedIndex.map((e, i) => (
            <Tile
              slogan={e.slogan}
              key={i}
              icon={e.icon}
              text={e.text}
              selected={e.select}
              index={i}
              setSelected={setSelectedIndex}
              list={selectedIndex}
            />
          ))}
          <button
            className="btn common-btn-color w-100 fw-bold mt-4"
            style={{ height: "70px" }}
            disabled={disabled}
            onClick={() => {
              const selectedItems = selectedIndex.filter((item) => item.select);
              const selectedItemsText = selectedItems.map((item) => item.text);
              dispatch(
                setDetailsData({ seatedPose: selectedItemsText.toString() })
              );
              navigate(RoutesName.partFourThree);
            }}
          >
            Next
          </button>
        </div>
      </div>
    </MobileViewLayout>
  );
};

const Tile = ({ icon, text, selected, index, setSelected, list, slogan }) => {
  const handleSelect = (index) => {
    const update = [...list];
    update[index].select = !update[index].select;
    setSelected([...update]);
  };
  return (
    <div
      className={`bg-white d-flex align-items-center mt-2 ${
        selected ? "border-main selected-background" : ""
      }`}
      style={{
        height: "75px",
        width: "100%",
        borderRadius: "15px",
        padding: "20px 20px",
      }}
      onClick={() => {
        handleSelect(index);
      }}
    >
      <img src={icon} alt="" width={32} height={32} />

      <h6 className="ms-3 mb-0 text-start">{text}</h6>
    </div>
  );
};
