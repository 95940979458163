import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LIKE from "../../assets/like.svg";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

export const PartTwoScreenFour = ({ bodyDetails, setBodyDetails }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  const data = bodyDetails.testData;
  const currentWeightInLbs =
    data.current.unit === "KG"
      ? data.current.weight / 0.45359237
      : data.current.weight;
  const lbsDifference = Math.round(
    currentWeightInLbs > data.desire.weight
      ? currentWeightInLbs - data.desire.weight
      : data.desire.weight - currentWeightInLbs
  );
  let IsKG = bodyDetails.testData.desire.unit === "KG" ? true : false;
  let weight =
    bodyDetails.currentWeight < bodyDetails.goalWeight
      ? Math.round(bodyDetails.goalWeight - bodyDetails.currentWeight)
      : Math.round(bodyDetails.currentWeight - bodyDetails.goalWeight);

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div
            style={{ flex: 1 }}
            className=" text-center align-items-center d-flex flex-column justify-content-center"
          >
            <img src={LIKE} width={46} height={47} alt="" srcset="" />
            {Math.round(bodyDetails.currentWeight) ===
            Math.round(bodyDetails.goalWeight) ? (
              <>
                <h3 className="fw-bold px-5 mt-3 text-center">
                  Keeping your weight is a great goal. Let's hit it!
                </h3>
                <p className="mt-2 text-secondary" style={{ fontSize: "14px" }}>
                  That's fantastic! Choosing to maintain your current weight is
                  a commendable decision that reflects a strong commitment to
                  your well-being.
                </p>{" "}
              </>
            ) : (
              <>
                <h3 className="fw-bold px-5 mt-3 text-center">
                  {bodyDetails.currentWeight < bodyDetails.goalWeight
                    ? "Gaining"
                    : " Losing"}{" "}
                  <span className="text-danger fw-400">
                    {" "}
                    {` ${IsKG ? weight : lbsDifference} ${
                      IsKG ? " kg" : " lbs"
                    }`}
                  </span>{" "}
                  is a great goal. Let's hit it!
                </h3>
                <p className="mt-2 text-secondary" style={{ fontSize: "14px" }}>
                  89% of users find the results of using EazyFit's plan clearly
                  noticeable, with a low likelihood of bouncing back.
                </p>{" "}
              </>
            )}
          </div>

          <button
            className="btn common-btn-color w-100 fw-bold"
            style={{ height: "70px" }}
            onClick={() => navigate(RoutesName.partTwoPrediction)}
          >
            {" "}
            Next
          </button>
        </div>
      </div>
    </MobileViewLayout>
  );
};
