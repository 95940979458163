import React from "react";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import BACK_ARROW from "../../assets/tab-header-images/back-arrow-white.svg";
import TIME_ICON from "../../assets/meal-svgs/time.svg";
import CALORY_ICON from "../../assets/meal-svgs/calories.svg";
import MEAL1 from "../../assets/meals/meal1.png";
import MEAL2 from "../../assets/meals/meal2.png";
import CHICKEN from "../../assets/meals/Chiken.jpeg";
import SANDWICH from "../../assets/meals/Sandwich.jpeg";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useNavigate, useLocation } from "react-router-dom";

const contants = {
  protein: 4,
  carbs: 4,
  fat: 9,
};

const Images = {
  Lunch: SANDWICH,
  Dinner: CHICKEN,
};

const calculate = (key, value, calories) => {
  value = Number(value.replace("g", ""));
  const percent = (value * contants[key] * 100) / calories;

  return percent.toFixed(0);
};

const MealSection = ({ data }) => {
  return (
    <div className="w-100 pb-2">
      <img
        src={data.image}
        className="w-100"
        style={{ maxHeight: "250px", objectFit: "cover" }}
      />
      <div className="py-3">
        <p
          style={{ fontSize: "16px" }}
          className="fw-semibold m-0 main-padding-auth"
        >
          {data.Meal}
        </p>
        <div
          className="d-flex gap-3 main-padding-auth mt-1"
          style={{ color: "#6C6E79", fontSize: "14px", lineHeight: "15px" }}
        >
          <div className="d-flex gap-1 align-items-center fw-se">
            <img src={TIME_ICON} alt="" style={{ width: "14px" }} />
            <p className="m-0">15 min</p>
          </div>
          <div className="d-flex gap-1 align-items-center">
            <img src={CALORY_ICON} alt="" style={{ width: "14px" }} />
            <p className="m-0">{data.Calories} cal</p>
          </div>
        </div>
        <hr style={{ backgroundColor: "#C1C1C1", height: "1px" }} />
        <p
          style={{ fontSize: "14px" }}
          className="fw-semibold text-center m-0 main-padding-auth"
        >
          Nutritional information
        </p>
        <p
          style={{ color: "#6C6E79", fontSize: "14px" }}
          className="text-center m-0 main-padding-auth mb-3"
        >
          (per serving)
        </p>
        <div className="d-flex pt-2 gap-4 px-5 justify-content-center">
          {[
            {
              title: "Carbs",
              percent: calculate("carbs", data.Carbs, data.Calories),
            },
            {
              title: "Protein",
              percent: calculate("protein", data.Protein, data.Calories),
            },
            data.Fat && {
              title: "Fat",
              percent: calculate("fat", data.Fat, data.Calories),
            },
          ].map(
            (data, index) =>
              data?.percent && (
                <div
                  className="d-flex flex-column align-items-center gap-1 "
                  key={index}
                  style={{ maxWidth: "82px" }}
                >
                  <CircularProgressbarWithChildren
                    strokeWidth={7}
                    value={Number(data.percent)}
                    key={index}
                    styles={{
                      path: {
                        stroke: "#2680EB",
                        width: "100px",
                      },
                      trail: {
                        stroke: "black",
                      },
                    }}
                    className="custom-progress-bar"
                  >
                    <div className="fw-semibold">
                      <span style={{ fontSize: "30px" }}>{data.percent}</span>
                      <span style={{ fontSize: "10px" }}>%</span>
                    </div>
                  </CircularProgressbarWithChildren>
                  <p className="fw-semibold m-0" style={{ fontSize: "14px" }}>
                    {data.title}
                  </p>
                </div>
              )
          )}
        </div>
        <hr style={{ backgroundColor: "#C1C1C1", height: "1px" }} />
        <div>
          {[
            { Calories: data.Calories + "cal" },
            { Protein: data.Protein },
            { Carbs: data.Carbs },
          ].map((d, index) => (
            <>
              <div
                key={index}
                className="d-flex gap-2 align-content-center justify-content-between main-padding-auth"
              >
                <p style={{ fontSize: "14px" }} className="fw-semibold m-0">
                  {Object.keys(d)[0]}
                </p>
                <p style={{ fontSize: "14px" }} className="fw-semibold m-0">
                  {Object.values(d)[0]}
                </p>
              </div>
              <hr style={{ backgroundColor: "#C1C1C1", height: "1px" }} />
            </>
          ))}
        </div>
        {data.Ingredients && (
          <div className="main-padding-auth d-flex flex-column gap-2">
            <p style={{ fontSize: "14px" }} className="fw-semibold m-0">
              Ingredients:
            </p>
            <ul className="ul-meal-details d-flex flex-column gap-1 border-1 border-start border-diet-plan">
              {typeof data.Ingredients === "string" ? (
                <li style={{ fontSize: "14px", fontWeight: "normal" }}>
                  {data.Ingredients}
                </li>
              ) : (
                data.Ingredients.map(
                  (ingredient, index) =>
                    ingredient && (
                      <li
                        key={index}
                        style={{ fontSize: "14px", fontWeight: "normal" }}
                      >
                        {ingredient}
                      </li>
                    )
                )
              )}
            </ul>
          </div>
        )}
        {data.Instructions && (
          <div className="main-padding-auth d-flex flex-column gap-2">
            <p style={{ fontSize: "14px" }} className="fw-semibold m-0">
              Instructions:
            </p>
            <ol className="ol-meal-details fw-semibold ps-3 d-flex flex-column gap-1">
              {typeof data.Instructions === "string" ? (
                <li style={{ fontSize: "14px", fontWeight: "normal" }}>
                  {data.Instructions}
                </li>
              ) : (
                data.Instructions.map(
                  (instruction, index) =>
                    instruction && (
                      <li
                        key={index}
                        style={{ fontSize: "14px", fontWeight: "normal" }}
                      >
                        {instruction}
                      </li>
                    )
                )
              )}
            </ol>
          </div>
        )}
      </div>
    </div>
  );
};

const MealDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    document
      .getElementsByClassName(
        "scrollbar-hidden overflow-scroll d-flex flex-column "
      )
      .item(0)
      .scrollTo(0, 0);
  }, []);

  return (
    <MobileViewLayout>
      <div
        className="position-absolute d-flex justify-content-center "
        style={{ width: "100%", top: "20px" }}
      >
        <div style={{ width: "100%" }} className="main-padding-auth">
          <img
            onClick={() => navigate(-1)}
            src={BACK_ARROW}
            alt=""
            style={{ width: "27px", height: "27px", cursor: "pointer" }}
          />
        </div>
      </div>
      {location.state ? (
        <>
          {location.state.details["Veg"] ? (
            <MealSection
              data={{
                ...location.state.details.Veg,
                image:
                  location.state.key === "Breakfast"
                    ? MEAL1
                    : Images[location.state.key],
              }}
            />
          ) : (
            <>
              <hr />
              <p className="m-0 text-center" style={{ fontWeight: "bold" }}>
                We Are Preparing Veg Meal Details.
              </p>
              <hr />
            </>
          )}
          {location.state.details["Non-Veg"] ? (
            <MealSection
              data={{
                ...location.state.details["Non-Veg"],
                image:
                  location.state.key === "Breakfast"
                    ? MEAL2
                    : Images[location.state.key],
              }}
            />
          ) : (
            <>
              <hr />
              <p className="m-0 text-center" style={{ fontWeight: "bold" }}>
                We Are Preparing Non-Veg Meal Details.
              </p>
              <hr />
            </>
          )}
        </>
      ) : (
        <>
          <hr />
          <p className="m-0 text-center" style={{ fontWeight: "bold" }}>
            We Are Preparing Your Meal Details.
          </p>
          <hr />
        </>
      )}

      <div className="main-padding-auth py-2">
        <p className="text-center fw-semibold " style={{ fontSize: "16px" }}>
          Remember, consistency is key to achieving your goals. Stay motivated,
          focus on progress, and celebrate your successes along the way!
        </p>
      </div>
    </MobileViewLayout>
  );
};

export default MealDetails;
