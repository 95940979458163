import React, { useEffect, useState } from "react";
import G2 from "../../assets/meh.svg";
import G1 from "../../assets/sad.svg";
import G from "../../assets/smile.svg";
import { useNavigate } from "react-router-dom";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";

export const PartFourTwo = () => {
  const [selectedIndex, setSelectedIndex] = useState([
    {
      icon: G1,
      text: "Breathless",
      slogan: "",
      select: false,
    },

    {
      icon: G2,
      text: "A Bit Dizzy, But Okay",
      slogan: "",
      select: false,
    },
    {
      icon: G,
      text: "Completely Fine",
      slogan: "",
      select: false,
    },
  ]);
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
    const set = selectedIndex.every((item) => item.select !== true);
    setDisabled(set);
  }, [selectedIndex]);

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center heading-font">
              How do you feel after tackling a flight of stairs?
            </h3>
            <p className="mt-4" style={{ color: "gray", fontSize: "12px" }}>
              This will help test cardiorespiratory function.
            </p>
          </div>
          {selectedIndex.map((e, i) => (
            <Tile
              slogan={e.slogan}
              key={i}
              icon={e.icon}
              text={e.text}
              selected={e.select}
              index={i}
              setSelected={setSelectedIndex}
              list={selectedIndex}
            />
          ))}
          <button
            className="btn common-btn-color w-100 fw-bold mt-4"
            style={{ height: "70px" }}
            disabled={disabled}
            onClick={() => {
              const selectedItems = selectedIndex.find((item) => item.select);
              dispatch(setDetailsData({ flightOfStairs: selectedItems.text }));
              navigate(RoutesName.partFourOne);
            }}
          >
            {" "}
            Next
          </button>
        </div>
      </div>
    </MobileViewLayout>
  );
};

const Tile = ({ icon, text, selected, index, setSelected, list, slogan }) => {
  const handleSelect = (index) => {
    const update = list.map((item, i) => ({
      ...item,
      select: i === index && !item.select,
    }));

    setSelected(update);
  };
  return (
    <div
      className={`bg-white d-flex align-items-center mt-2 ${
        selected ? "border-main selected-background" : ""
      }`}
      style={{
        height: "75px",
        width: "100%",
        borderRadius: "15px",
        padding: "20px 20px",
      }}
      onClick={() => {
        handleSelect(index);
      }}
    >
      <img src={icon} alt="" width={32} height={32} />

      <h6 className="ms-2 text-start mb-0 ms-3">{text}</h6>
    </div>
  );
};
