import React, { useRef } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import EXERCISE_IMG from "../assets/tab-header-images/exercise.svg";
import EXERCISE_IMG_SELECTED from "../assets/tab-header-images/exercise-selected.svg";
import MEAL_IMG from "../assets/tab-header-images/meal.svg";
import MEAL_IMG_SELECTED from "../assets/tab-header-images/meal-selected.svg";
import { RoutesName } from "../utils/RoutesName";
import { useDispatch, useSelector } from "react-redux";
import Menu from "../widgets/Menu";
import SUPPORT from "../assets/support.svg";
import TERMS from "../assets/terms-policy.svg";
import MANAGE from "../assets/manage.svg";
import LOGOUT from "../assets/logout.svg";
import { logoutState } from "../slices/AuthSlice";
import ActionSheet from "actionsheet-react";
import ApiService from "../service/api";
import { setMealPlanData } from "../slices/MealPlanSlice";
import { setDetailsData } from "../slices/DetailsSlice";

const isValidArray = (data) => {
  return data && Array.isArray(data) && data.length > 0;
};

const isValidObject = (data) => {
  return typeof data === "object" && data
    ? Object.keys(data).length > 0
    : false;
};

const BottomTabLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const mealPlan = useSelector((state) => state.mealState);
  const ref = useRef();

  const handleOpen = () => {
    ref.current.open();
  };

  const handleProfileClick = () => {
    navigate(RoutesName.profile);
  };

  const handleSupportClick = () => {
    const email = "support@eazyfit.app";
    const subject = "Help & Support";

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    window.location.href = mailtoLink;
  };

  const actionData = [
    {
      id: 1,
      title: "Support",
      image: SUPPORT,
      handleClick: handleSupportClick,
    },
    {
      id: 2,
      title: "Terms of Service",
      image: TERMS,
      handleClick: () => {
        window.scrollTo(0, 0);
        navigate(RoutesName.terms);
      },
    },
    {
      id: 3,
      title: "Privacy Policy",
      image: TERMS,
      handleClick: () => {
        window.scrollTo(0, 0);
        navigate(RoutesName.privacy);
      },
    },
    {
      id: 4,
      title: "Manage Subscription",
      image: MANAGE,
      handleClick: () => {
        window.scrollTo(0, 0);
        navigate(RoutesName.subscription);
      },
    },
    {
      id: 5,
      title: "Logout",
      image: LOGOUT,
      handleClick: () => {
        dispatch(logoutState());
        navigate(RoutesName.login);
      },
    },
  ];

  React.useEffect(() => {
   if(!mealPlan.details){
    ApiService.getMealPlanList()
    .then((res) => {
      if (typeof res.data === "object") {
        dispatch(setMealPlanData(res.data));
        dispatch(setDetailsData(res.data.DietNeeds));
      }
    })
    .catch((err) => console.log(err));
   }
  }, []);

  return (
    <>
      <div
        style={{
          height: "calc(100dvh - 65px)",
        }}
        className="scrollbar-hidden overflow-scroll d-flex flex-column "
      >
        <div className="w-100 d-flex align-items-center flex-column position-sticky top-0 start-0 z-1 bg-white pb-3 border-1 border-bottom border-diet-plan">
          <Menu menuClick={handleOpen} profileClick={handleProfileClick} />
        </div>
        <Outlet />
      </div>
      <div
        className="position-fixed p-2 fixed-bottom left-0 border-top border-1 bg-white border-diet-plan"
        style={{ zIndex: 1 }}
      >
        <div className="d-flex justify-content-center align-items-center  mobile-view">
          <div
            onClick={() => {
              navigate(RoutesName.courses);
            }}
            className="d-flex flex-column justify-content-center align-items-center fw-semibold w-50"
            style={{
              fontSize: "12px",
              fontWeight: 600,
              color: location.pathname.includes(RoutesName.courses)
                ? "#2680EB"
                : "#000000",

              cursor: "pointer",
            }}
          >
            <img
              height={30}
              width={20}
              src={
                location.pathname.includes(RoutesName.courses)
                  ? EXERCISE_IMG_SELECTED
                  : EXERCISE_IMG
              }
              alt="Guides"
              style={{ color: "#4286F5" }}
            />
            Exercise Plan
          </div>
          {mealPlan.details &&
            (mealPlan.details.DietMeal.breakfast ||
              mealPlan.details.DietMeal.lunch ||
              mealPlan.details.DietMeal.dinner ||
              mealPlan.details.DietMeal.snacks ||
              isValidArray(mealPlan.details.DietMeal.snacksTips) ||
              isValidArray(mealPlan.details.DietMeal.additionalTips) ||
              isValidObject(mealPlan.details.DietMeal.breakfastDetail) ||
              isValidObject(mealPlan.details.DietMeal.lunchDetail) ||
              isValidObject(mealPlan.details.DietMeal.dinnerDetail)) && (
              <>
                <div
                  style={{
                    height: "44px",
                    width: "1px",
                    backgroundColor: "#c1c1c1",
                  }}
                ></div>
                <div
                  onClick={() => {
                    navigate(RoutesName.meal);
                  }}
                  className="d-flex flex-column justify-content-center align-items-center fw-semibold w-50"
                  style={{
                    fontSize: "12px",
                    fontWeight: 600,
                    color: location.pathname.includes(RoutesName.meal)
                      ? "#2680EB"
                      : "#000000",

                    cursor: "pointer",
                  }}
                >
                  <img
                    height={30}
                    width={20}
                    src={
                      location.pathname.includes(RoutesName.meal)
                        ? MEAL_IMG_SELECTED
                        : MEAL_IMG
                    }
                    alt="Chat"
                  />
                  Meal Plan
                </div>
              </>
            )}
        </div>
      </div>
      <ActionSheet
        ref={ref}
        className="actionSheet"
        sheetStyle={{
          backgroundColor: "#000000",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          margin: "0 auto",
          right: 0,
        }}
        bgTransition="opacity 0.3s ease-in-out"
        sheetTransition="transform 0.3s ease-in-out"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: "15px 15px",
          }}
        >
          <ListMenu arrayData={actionData} />
        </div>
      </ActionSheet>
    </>
  );
};

const ListMenu = ({ arrayData }) => {
  return (
    <>
      {arrayData.map((d) => (
        <div
          className="d-flex flex-row mt-2 mb-2 align-items-center"
          key={d?.id}
          onClick={d?.handleClick}
        >
          <img className="mx-3" alt="" src={d?.image} height={30} width={30} />
          <p className="m-0 p-0 text-white" style={{ fontSize: "14px" }}>
            {d?.title}
          </p>
        </div>
      ))}
    </>
  );
};

export default React.memo(BottomTabLayout, () => true);
