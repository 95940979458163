import React from "react";
import arrows from "../assets/ng-arrows.svg";
import character from "../assets/ng-character.png";

const Transformation = () => {
  return (
    <div>
      <div>
        <img
          src={character}
          alt=""
          style={{ width: "100%", position: "relative", zIndex: 2 }}
        />
        <div className="arrow-container animateArrow">
          <img src={arrows} className="arrow" alt="" />
        </div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="now-label">Now</div>
        <div className="now-label goal-label">Goal</div>
      </div>
      <p
        className="m-0 p-0 mt-3"
        style={{ fontSize: "18px", color: "#6C6E79" }}
      >
        89% of users find the results of using{" "}
        <span id="get-plan-scroll">EazyFit's plan clearly noticeable</span>
      </p>
      <div id="get-plan-scroll-new"></div>
      <p
        className="m-0 p-0 mt-3 mb-1"
        style={{ fontSize: "10px", color: "#ABABAB" }}
      >
        *The image is not intended to represent the user. Results vary per
        person and are not guaranteed.
      </p>
    </div>
  );
};

export default Transformation;
