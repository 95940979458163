import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import bulb from "../../assets/light-bulb.png";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

const PartThreeTwo = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(7);
  let arrayAge = [];
  for (let index = 18; index < 66; index++) {
    arrayAge.push(index);
  }

  const nextIndex = () => {
    if (currentIndex == arrayAge.length - 1) {
      setCurrentIndex({ currentIndex: 0 });
    }
    setCurrentIndex(currentIndex + 1);
  };

  const prevIndex = () => {
    if (currentIndex == 0) {
      setCurrentIndex(arrayAge.length - 1);
    }
    setCurrentIndex(currentIndex - 1);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  return (
    <MobileViewLayout>
      {" "}
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center">What's your age?</h3>
            <div className="d-flex justify-content-center align-items-center age-text-box px-1">
              <img
                src={bulb}
                alt=""
                width={48}
                height={48}
                srcSet=""
                className="mx-2"
              />
              <p className="p-0 m-0 header-box-text">
                This will help us make adjustment to your personal plan
              </p>
            </div>
          </div>
          <div
            className="d-flex justify-content-end align-items-center"
            style={{
              flex: 10,
              width: "100%",
              height: "200px",
              paddingRight: 25,
            }}
          >
            <ReactScrollWheelHandler
              upHandler={prevIndex}
              downHandler={nextIndex}
              style={{
                transition: "background-color .4s ease-out",
              }}
              className="d-flex align-items-center justify-content-center flex-column"
            >
              <p
                className="p-0 m-0"
                style={{ fontSize: "30px", color: "#EFF0F4" }}
              >
                {arrayAge[currentIndex] <= 18
                  ? "-"
                  : arrayAge[currentIndex - 2]}
              </p>
              <p
                className="p-0 m-0"
                style={{ fontSize: "40px", color: "#6C6E79" }}
              >
                {arrayAge[currentIndex] <= 18
                  ? "-"
                  : arrayAge[currentIndex - 1]}
              </p>
              <p className="p-0 m-0 px-2" style={{ fontSize: "50px" }}>
                {arrayAge[currentIndex]}
              </p>
              <p
                className="p-0 m-0"
                style={{ fontSize: "40px", color: "#6C6E79" }}
              >
                {arrayAge[currentIndex] >= 65
                  ? "-"
                  : arrayAge[currentIndex + 1]}
              </p>
              <p
                className="p-0 m-0"
                style={{ fontSize: "30px", color: "#EFF0F4" }}
              >
                {arrayAge[currentIndex] >= 65
                  ? "-"
                  : arrayAge[currentIndex + 2]}
              </p>
            </ReactScrollWheelHandler>
            <span
              style={{
                textAlign: "center",
                color: "#6C6E79",
                fontSize: "18px",
                fontWeight: "lighter",
              }}
            >
              years old
            </span>
          </div>
          <button
            className="btn common-btn-color w-100 fw-bold mt-4"
            style={{ height: "70px" }}
            onClick={() => {
              navigate(RoutesName.partThreeGoals);
            }}
          >
            {" "}
            Next
          </button>
        </div>
      </div>
    </MobileViewLayout>
  );
};

export default PartThreeTwo;
