import { createSlice } from "@reduxjs/toolkit";

function check_session() {
  if (localStorage.getItem("token") !== null) {
    const data = JSON.parse(localStorage.getItem("token"));
    if (data) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

const initialState = {
  user:
    localStorage.getItem("token") !== null
      ? JSON.parse(localStorage.getItem("token"))
      : null,
  loggedIn: check_session(),
};

export const authSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    logoutState: (state) => {
      console.log("logout");
      localStorage.removeItem("token");
      state.loggedIn = false;
      state.subscribed = false;
      state.user = null;
    },
    checkLogin: (state) => {
      state.loggedIn = check_session();
    },
  },
});

export const { logoutState, checkLogin, incrementByAmount } = authSlice.actions;

export default authSlice.reducer;
