import React from "react";
import { useNavigate } from "react-router-dom";
import guarantee from "../../assets/badge.png";
import ig from "../../assets/instagram.svg";
import SECURITY from "../../assets/security.svg";
import star from "../../assets/star-blue.svg";
import character from "../../assets/goal-character.png";
import { RoutesName } from "../../utils/RoutesName";
import { features, paymentTypes, reviews } from "../../utils/data";

const GatewayFeatures = () => {
  const navigate = useNavigate();

  return (
    <>
      <p
        className="m-0 p-0 mt-5"
        style={{ fontSize: "17px", fontWeight: "600" }}
      >
        Guaranteed <span style={{ color: "#2680EB" }}>Safe</span> Checkout
        <span className="mx-2">
          <img
            alt=""
            src={SECURITY}
            style={{ height: "20px", width: "16px" }}
          />
        </span>
      </p>
      <div className="d-flex flex-row justify-content-center align-items-center mt-3">
        {paymentTypes.map((data) => (
          <img key={data?.id} className="mx-2" alt="" src={data?.src} />
        ))}
      </div>
      <div className="mt-5">
        <h3 className="mb-4" style={{ fontWeight: "600" }}>
          What You Get
        </h3>
        {features.map((data) => (
          <div key={data.id} className="d-flex flex-direction-row mt-2">
            <img
              className="my-1 me-2"
              alt=""
              src={star}
              style={{ width: "15px", height: "14px" }}
            />
            <p className="m-0 p-0 text-14" style={{ textAlign: "left" }}>
              {data?.title}
            </p>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <h3 style={{ fontWeight: "600" }}>Users Love Our Plans</h3>
        {reviews.map((data) => (
          <div key={data.id} className="d-flex flex-column mt-4">
            <div className="d-flex flex-row justify-content-between w-100">
              <div className="d-flex flex-row align-items-center">
                <img
                  className="my-1 me-2"
                  alt=""
                  src={data?.img}
                  style={{ width: "30px", height: "30px" }}
                />
                <p className="m-0 p-0 text-14" style={{ fontWeight: "500" }}>
                  {data?.name}
                </p>
              </div>
              <img alt="" src={ig} />
            </div>
            <div
              className="mt-1"
              style={{ border: "1px solid #D6D6D6", borderRadius: "5px" }}
            >
              <p
                className="m-0 px-3 py-2 text-14"
                style={{ textAlign: "left" }}
              >
                {data?.title}
              </p>
            </div>
          </div>
        ))}
      </div>
      <h3 className="mt-5" style={{ fontWeight: "600" }}>
        Our users meet their goals
      </h3>
      <img className="mt-3" src={character} alt="" />
      <p
        className="m-0 p-0 mt-3"
        style={{ fontSize: "10px", color: "#ABABAB" }}
      >
        *The image is not intended to represent the user. Results vary per
        person and are not guaranteed.
      </p>
      <div className="mt-4 mb-3">
        <img
          alt=""
          src={guarantee}
          style={{ height: "114px", width: "120px" }}
        />
        <h3 style={{ fontWeight: "600" }}>100% Money-Back Guarantee</h3>
        <p className="mt-2 text-14" style={{ color: "#6C6E79" }}>
          We believe that our plan can work for you and you will get visible
          results in 4 weeks! We are even willing to return your money if you
          don't see visible results and can prove that you followed our plan.
          Please double check our{" "}
          <span
            style={{ textDecoration: "underline" }}
            onClick={() => {
              window.scrollTo(0, 0);
              navigate(RoutesName.terms);
            }}
          >
            Refund Policy
          </span>{" "}
          to understand all the requirements.
        </p>
      </div>
    </>
  );
};

export default GatewayFeatures;
