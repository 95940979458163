import applePay from "../assets/apay.svg";
import master from "../assets/master-card.svg";
import PayPal from "../assets/paypal.svg";
import heartEyes from "../assets/smile-with-heart-eyes.png";
import hearts from "../assets/smile-with-hearts.png";
import thumbsUp from "../assets/thumbs-up.png";
import visa from "../assets/visa.svg";

export const paymentTypes = [
  { id: 1, src: visa },
  { id: 2, src: master },
  { id: 3, src: PayPal },
  { id: 4, src: applePay },
];

export const features = [
  { id: 1, title: "1,000+ trainer-certified exercises" },
  { id: 2, title: "120+ tasty and good-for-you recipes" },
  { id: 3, title: "Guided workouts with step-by-step video tutorials" },
  {
    id: 4,
    title:
      "A personalized fitness plan tailored to your needs and fitness level",
  },
  {
    id: 5,
    title:
      "All-in-one: a combination of cardio, strength, and recovery workouts for the best results",
  },
];

export const reviews = [
  {
    id: 1,
    img: heartEyes,
    name: "@atellb",
    title:
      "Fantastic fitness app! Easy to use, keeps me motivated, and tracks my progress seamlessly.",
  },
  {
    id: 2,
    img: hearts,
    name: "@lkleir",
    title:
      "Love this fitness app! Easy tracking, personalized plans, and great motivation. Highly recommend!",
  },
  {
    id: 3,
    img: thumbsUp,
    name: "@anntp",
    title:
      "This fitness app is a game-changer! It's user-friendly, keeps me motivated, and the personalized plans make reaching my goals a breeze. Highly impressed!",
  },
];
