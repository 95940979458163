import React, { useEffect, useState } from "react";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

const Terms = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  const listOne = [
    {
      id: 1,
      data: "“Account” – means a personal account of a user of the Platform services, which is provided by the Platform Administrator under the Platform Terms of Use or similar document. The Account is accessed with a Platform ID (account name) and a password.",
    },
    {
      id: 2,
      data: "“Company”, “We” – mean WEBFY, a company incorporated in the Ireland at the following address: Black Church. St Mary’s Place. Dublin D07 P4AX. Ireland.",
    },
    {
      id: 3,
      data: "“Content” – means all kinds of content, including, among other, data, texts, images, videos, sounds, links, software and other pieces of information in any form, which are made available to You through the WebApp.",
    },
    {
      id: 4,
      data: "“Device” – means a smartphone, a tablet or another similar mobile device that You either own or otherwise legally control exclusively for Your personal non-commercial use.",
    },
    {
      id: 5,
      data: "“EULA” – means this End User License Agreement, which is a binding agreement between You and the Company. The EULA may incorporate other documents, such as Privacy Policy that regulate relations between You and Us with regard to using the WebApp. If a document represents a part of the EULA, it has a clear indication thereof.",
    },
    {
      id: 6,
      data: "“Intellectual Property Rights” – means, collectively, rights under any patent, trademark, copyright and trade secret laws, and any other intellectual property or proprietary rights recognized in any country or jurisdiction worldwide, including, without limitation, moral or similar rights.",
    },
    {
      id: 7,
      data: "“Subscription” – means a period, during which You use the WebApp as a whole or certain additional Content for a fee.",
    },
    {
      id: 8,
      data: "“User-generated Content” – means any form of Content, including, among other, data, texts, images, videos, sounds, links, that have been posted by users to online platforms, for example, social networks (e.g. Instagram, Facebook).",
    },
    {
      id: 9,
      data: "“You” – means an individual using the WebApp.",
    },
  ];

  const listTwo = [
    {
      id: 1,
      data: "We may introduce changes to this EULA from time to time. You shall regularly check the latest version of the EULA at the link, available on the web-page.",
    },
    {
      id: 2,
      data: "If You do not agree with the terms of the updated EULA, You are not allowed to use the WebApp starting from the day, on which the updated EULA takes effect. In this case You shall not use the WebApp and cancel renewal of your Subscriptions.",
    },
  ];

  const listThree = [
    {
      id: 1,
      data: "If You intend use the WebApp on any Device not owned by You, You must have the owner’s permission to do so. You will be responsible for complying with this EULA whether or not You own the Device.",
    },
    {
      id: 2,
      data: "If You do not own the Device, You are allowed to use the WebApp only through Your Account.",
    },
    {
      id: 3,
      data: "If You use the Device together with other people, please, make sure that nobody except You can use the WebApp from Your Account. All actions taken in Your Account are considered Your actions in disregard of who actually takes such actions. You are fully responsible for all actions taken in Your Account.",
    },
    {
      id: 4,
      data: "If You decide to sell or otherwise dispose of the Device, You shall delete the WebApp from the Device.",
    },
  ];

  const listFour = [
    {
      id: 1,
      data: "The WebApp is designed for users over 16. If You use the WebApp, You confirm that You are at least 16 years old and have full legal capacity to enter into the Agreement.",
    },
    {
      id: 2,
      data: "If You are between 16 and 18 years old, Your parent, guardian or other representative of legal age has to check and agree to this EULA on Your behalf. If You use the WebApp, You confirm that Your parent, guardian or other representative of legal age has agreed to the terms of this EULA and permits You to use the WebApp.",
    },
  ];

  const listFive = [
    {
      id: 1,
      data: "We hereby grant You a non-exclusive, personal, limited, revocable and non-transferable license to access, download and run the Webapp only on Your Device, provided that You agree to comply with all the terms and conditions of this EULA. Please, take into consideration that the WebApp is licensed, not sold, to You.",
    },
    {
      id: 2,
      data: "The Company or the licensor of the Company is the only holder of Intellectual Property Rights with regard to the WebApp. The Company retains all rights, title and interest in and to the WebApp and reserves all rights not expressly granted to You in this EULA.",
    },
    {
      id: 3,
      data: "You may not sell, resell, distribute, redistribute, rent, lease, sublicense, assign, copy, or in any other way transfer or grant any rights to the WebApp to any third party.",
    },
    {
      id: 4,
      data: "You may not distribute or make the WebApp available over any network where it could be downloaded or used by multiple users at the same time, except when it is expressly authorized by Us.",
    },
    {
      id: 5,
      data: "You may not use any automatic or manual device or process to interfere with the proper working of the WebApp. However, You may use any kind of device or process to delete the WebApp from Your Device.",
    },
    {
      id: 6,
      data: "You may not decompile, disassemble, reverse engineer or create derivative works of the WebApp or any parts thereof. All modifications or enhancements to the WebApp remain the sole property of Company.",
    },
    {
      id: 7,
      data: "You may not use the WebApp in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with this EULA or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the WebApp.",
    },
  ];

  const listSix = [
    {
      id: 1,
      data: "This WebApp as a whole or its certain part (additional Content) is provided on a paid subscription basis. The fee may vary depending on the period of the Subscription duration.",
    },
    {
      id: 2,
      data: "The Subscription will renew automatically for the same period at the end of the Subscription at the cost of the chosen package. Your Account will be charged for the renewed Subscription within 24 hours prior to the end of the current Subscription.",
    },
    {
      id: 3,
      data: "You may cancel renewal of the Subscription at least 24 hours before the end of the Subscription: in this case the WebApp as a whole or its additional Content will be available to You until the end of the Subscription. In order to cancel automatic renewal of the Subscription, please, change subscription settings of Your Account. After the end of the Subscription the WebApp as a whole or its additional Content will be available only if a new Subscription is purchased.",
    },
    {
      id: 4,
      data: "Your subscription will not stop automatically. You must cancel automatic renewal of Your subscription in order not to be charged with the cost of the new Subscription.",
    },
    {
      id: 5,
      data: "The WebApp provides for a free 7-day trial period. You may use the WebApp of its additional Content free of charge during this period. Please, take into consideration that at the end of the trial period it will automatically transform into a paid Subscription for the shortest period available and Your Account will be charged for the cost of such Subscription, unless You cancel the Subscription through the subscription settings of Your Account at least 24 hours prior to the end of the free trial period.",
    },
  ];

  const listSeven = [
    {
      id: 1,
      data: "The costs of all Subscriptions are available on the web-page.",
    },
    {
      id: 2,
      data: "When You purchase a Subscription, the Platform Administrator charges Your Account and collects money on Our behalf. The payment is processed by a payment processing system implemented by the Platform Administrator.",
    },
    {
      id: 3,
      data: "Please, kindly notice that We neither process Your payment nor have any access to Your payment information, such as, among other, Your bank card data, Your name or any other related information.",
    },
    {
      id: 4,
      data: "Should You have any question with regard to payment processing, please, submit Your issue to user support service of the Platform Administrator.",
    },
    {
      id: 5,
      data: "Your Account is charged with the cost of the new Subscription within 24 hours prior the end of the current Subscription. If You cancel automatic renewal within the last 24 hours of the Subscription, such cancellation will take effect at the end of the new Subscription.",
    },
    {
      id: 6,
      data: "Please, kindly notice that We do not make refunds. The digital content is made available to You immediately after the Subscription fee is charged to Your Account.",
    },
  ];

  const listEight = [
    {
      id: 1,
      data: "As far as You may access posts of other Instagram users, You may be exposed to potentially objectionable User-generated Content. Please, take into account that the Company neither controls nor pre-checks any User-generated Content posted on any social network.",
    },
    {
      id: 2,
      data: "Any kind of modifications or censorship by the Company with regard to the User-generated Content may be considered violation of human rights or copyright and may potentially mislead You.",
    },
    {
      id: 3,
      data: "If You have been exposed to any kind of objectionable content, You may use functionality of the respective social network to track and delete objectionable User-generated Content.",
    },
    {
      id: 4,
      data: "Under no circumstances shall the Company be responsible for any User-generated Content.",
    },
  ];

  const listNine = [
    {
      id: 1,
      data: "To the fullest extent permitted by applicable law, We and Our affiliates, officers, employees, agents, partners, and licensors expressly disclaim all warranties of any kind, express or implied, as to the accuracy, adequacy or completeness of any of the information contained in Our WebApp or created by the services.",
    },
    {
      id: 2,
      data: "The Content in Our WebApp is provided to You on an “as is” basis, without any warranties as to merchantability or fitness for a particular purpose or use. We do not guarantee that You will attain any anticipated result from the use of the WebApp.",
    },
    {
      id: 3,
      data: "We make no warranty that:",
    },
    {
      id: "a",
      data: "the WebApp will meet Your requirements;",
    },
    {
      id: "b",
      data: "the WebApp will be error-free, secure, or uninterrupted;",
    },
    {
      id: "c",
      data: "any WebApp functionality errors will be corrected",
    },
    {
      id: "d",
      data: "the quality of the WebApp will meet Your expectations.",
    },
    {
      id: 4,
      data: "We shall have no responsibility or liability for any errors or omissions. We are not responsible for any damages, whether direct or indirect, special or consequential, suffered by You or by any other person from the use of the WebApp, even if You have been advised of the possibility of such damages. No advice or information, whether oral or written, obtained by You from Us or Our WebApp shall create any warranty not expressly stated in the EULA. Under no circumstances shall Our liability or of any of Our affiliates pursuant to any cause of action, whether in contract, tort, or otherwise, exceed the fee paid by You for access to the WebApp. Furthermore, We shall have no responsibility or liability for delays or failures due to circumstances beyond Our control.",
    },
  ];

  const listTen = [
    {
      id: 1,
      data: "We may transfer Our rights and obligations under this EULA to any other organization or individual without Your consent. We will always tell You in writing if this happens and We will ensure that the transfer will not affect Your rights under the EULA.",
    },
    {
      id: 2,
      data: "You may only transfer Your rights or Your obligations under this EULA to another person if We agree in writing.",
    },
  ];

  const listTwelve = [
    {
      id: 1,
      data: "Each of the paragraphs of this EULA operates separately. If any court or relevant authority decides that any of them is unlawful, the remaining paragraphs will remain in full force and effect.",
    },
    {
      id: 2,
      data: "Even if We delay enforcing this EULA, We can still enforce it later. If We do not insist immediately that You do anything You are required to do under this EULA terms, or if We delay taking steps against You in respect of Your breaking the EULA, that will not mean that You do not have to do those things and it will not prevent Us taking steps against You at a later date.",
    },
    {
      id: 3,
      data: "This EULA shall be governed and construed in accordance with the laws of Dublin, Ireland, without regard to its conflict of law provisions.",
    },
    {
      id: 4,
      data: "If any dispute, controversy or difference arises between You and the Company, it shall be settled amicably through negotiations. In case such dispute, controversy or difference cannot be settled amicably through negotiations within a thirty 30-day period it shall be shall be settled by the Ireland Arbitration Association in accordance with its International Expedited Procedures.",
    },
  ];

  return (
    <MobileViewLayout>
      {" "}
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center d-flex flex-column pb-4"
          style={{ minHeight: "100dvh", backgroundColor: "#F7F7F7" }}
        >
          <div className="d-flex justify-content-center bottom-border">
            <div style={{ position: "relative" }}>
              <div style={{ position: "absolute", left: "-40px" }}>
                <IoChevronBackCircleOutline
                  size={30}
                  onClick={() => navigate(-1)}
                />
              </div>
              <h4 className="m-0 p-0">Terms and Conditions</h4>
            </div>
          </div>
          <div
            style={{ textAlign: "left" }}
            className="d-flex flex-column main-padding mt-4"
          >
            <p>
              This End User License Agreement is a valid agreement between You
              and the Company. Please, read this EULA carefully. The rules of
              the EULA are binding upon You. If You do not agree to be bound by
              the rules of the EULA, please, do not use the WebApp. If You
              continue to use the WebApp, this will mean that You have read and
              understood the EULA and agree to be bound by it.
            </p>
            <h3 className="mt-2">1. DEFINITIONS AND TERMS</h3>
            <p>
              In order to understand the rules of the EULA clearly you shall
              familiarize yourself with the meaning of terms used in the EULA.
              In this EULA the following terms, when capitalized, shall have the
              following meanings:
            </p>
            {listOne.map((d) => (
              <p key={d.id}>
                {d?.id}. {d?.data}
              </p>
            ))}
            <h3 className="mt-2">2. CHANGES OF THE EULA</h3>
            {listTwo.map((d) => (
              <p key={d.id}>
                {d?.id}. {d?.data}
              </p>
            ))}
            <h3 className="mt-2">3. DEVICE</h3>
            {listThree.map((d) => (
              <p key={d.id}>
                {d?.id}. {d?.data}
              </p>
            ))}
            <h3 className="mt-2">4. LEGAL CAPACITY AND AGE</h3>
            {listFour.map((d) => (
              <p key={d.id}>
                {d?.id}. {d?.data}
              </p>
            ))}
            <h3 className="mt-2">5. INTELLECTUAL PROPERTY</h3>
            {listFive.map((d) => (
              <p key={d.id}>
                {d?.id}. {d?.data}
              </p>
            ))}
            <h3 className="mt-2">6. SUBSCRIPTION AND TRIAL PERIOD</h3>
            {listSix.map((d) => (
              <p key={d.id}>
                {d?.id}. {d?.data}
              </p>
            ))}
            <h3 className="mt-2">7. FEES AND PURCHASE TERMS</h3>
            {listSeven.map((d) => (
              <p key={d.id}>
                {d?.id}. {d?.data}
              </p>
            ))}
            <h3 className="mt-2">8. USER-GENERATED CONTENT</h3>
            {listEight.map((d) => (
              <p key={d.id}>
                {d?.id}. {d?.data}
              </p>
            ))}
            <h3 className="mt-2">9. DISCLAIMER</h3>
            {listNine.map((d) => (
              <p key={d.id}>
                {d?.id}
                {typeof d?.id === "number" ? "." : ")"} {d?.data}
              </p>
            ))}
            <h3 className="mt-2">10. TRANSFER OF RIGHTS</h3>
            {listTen.map((d) => (
              <p key={d.id}>
                {d?.id}. {d?.data}
              </p>
            ))}
            <h3 className="mt-2">11. TRANSFER OF RIGHTS</h3>
            <p>
              1. We may end Your rights to use the WebApp at any time by
              contacting You if You have broken the rules of this EULA in a
              serious way. If what You have done can be put right We will give
              You a reasonable opportunity to do so.
            </p>
            <p>2. If We end Your rights to use the WebApp:</p>
            <p>
              a) You must stop all activities authorized by this EULA, including
              Your use of the WebApp.
            </p>
            <p>
              b) You must delete or remove the WebApp from all Devices and
              immediately destroy all copies of the WebApp which You have and
              confirm to Us that You have done this..
            </p>
            <h3 className="mt-2">12. MISCELLANEOUS</h3>
            {listTwelve.map((d) => (
              <p key={d.id}>
                {d?.id}. {d?.data}
              </p>
            ))}
            <p>The number of arbitrators shall be one.</p>
            <p>The place of arbitration shall be Dublin, Ireland.</p>
            <p>
              The arbitration shall be held, and the award rendered, in English.
            </p>
            <p>
              Each party (i.e. You and the Company) shall bear its own expenses,
              but the parties shall share equally the expenses of the
              arbitration tribunal.
            </p>
            <p>
              5. Support. If You want to learn more about the WebApp or have any
              problems using it, please, contact Us at{" "}
              <a href="mailto:support@eazyfit.app">support@eazyfit.app</a>
            </p>
          </div>
        </div>
      </div>
    </MobileViewLayout>
  );
};

export default Terms;
