import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BACK from "../../assets/back.svg";
import FOOT from "../../assets/foot.svg";
import ANKLE from "../../assets/g1815.svg";
import KNEE from "../../assets/knee.svg";
import BULB from "../../assets/light-bulb.png";
import NONE from "../../assets/none.svg";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";
import ApiService from "../../service/api";

export const PartThreeSeven = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState([
    {
      icon: NONE,
      text: "No",
      slogan: "",
      select: false,
    },
    {
      icon: FOOT,
      text: "Ankles",
      slogan: "",
      select: false,
    },
    {
      icon: BACK,
      text: "Back",
      slogan: "",
      select: false,
    },
    {
      icon: KNEE,
      text: "Knees",
      slogan: "",
      select: false,
    },
    {
      icon: ANKLE,
      text: "Legs",
      slogan: "",
      select: false,
    },
  ]);
  const [isVisible, setIsVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
    const set = selectedIndex.every((item) => item.select !== true);
    setDisabled(set);
  }, [selectedIndex]);

  useEffect(() => {
    if (location.state?.data) {
      const array = location.state.data.split(",");
      const updatedItems = selectedIndex.map((item) => {
        if (array.includes(item.text)) {
          return { ...item, select: true };
        } else {
          return { ...item, select: false };
        }
      });
      setSelectedIndex(updatedItems);
    }
  }, []);


  const updateData = (value) => {
    ApiService.updateGoals({ injuriesAreas: value })
      .then((res) => navigate(-1))
      .catch((err) => {
        console.log(err);
        navigate(-1);
      });
  };

  const handleNextClick = () => {
    const selectedItems = selectedIndex.filter((item) => item.select);
    const selectedItemsText = selectedItems.map((item) => item.text);
    dispatch(
      setDetailsData({ injuriesAreas: selectedItemsText.toString() })
    );
    location.state?.data
      ? updateData(selectedItemsText.toString())
      : navigate(RoutesName.partFourIntro);
  }

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center heading-font">
              Have you ever experienced any issues or injuries in these areas
              before?
            </h3>
            <div
              className="d-flex mt-3 px-1 align-items-center text-center justify-content-center gender-text-main"
              style={{ height: "60px", color: "#6C6E79" }}
            >
              <img className="mx-1" src={BULB} alt="" />
              <p className="p-0 m-0 header-box-text ">
                We will filter any unsafe or risky workouts
              </p>
            </div>
          </div>
          {selectedIndex.map((e, i) => (
            <Tile
              slogan={e.slogan}
              key={i}
              icon={e.icon}
              text={e.text}
              selected={e.select}
              index={i}
              setSelected={setSelectedIndex}
              list={selectedIndex}
            />
          ))}
          <button
            className={`btn w-100 fw-bold mt-4 ${
              location.state?.data ? "profile-button-bg" : "common-btn-color"
            }`}
            style={{ height: "70px" }}
            disabled={disabled}
            onClick={handleNextClick}
          >
            {location.state?.data ? "Save" : "Next"}
          </button>
        </div>
      </div>
    </MobileViewLayout>
  );
};

const Tile = ({ icon, text, selected, index, setSelected, list, slogan }) => {
  const handleSelect = (index) => {
    if (index != 0) {
      const update = [...list];
      update[0].select = false;

      update[index].select = !update[index].select;
      setSelected([...update]);
    } else {
      let update = [...list];
      update = update.map((e) => {
        return {
          ...e,
          select: false,
        };
      });
      update[0].select = true;
      setSelected([...update]);
    }
  };
  return (
    <div
      className={`bg-white d-flex align-items-center mt-2 ${
        selected ? "border-main selected-background" : ""
      }`}
      style={{
        height: "75px",
        width: "100%",
        borderRadius: "15px",
        padding: "20px 20px",
      }}
      onClick={() => {
        handleSelect(index);
      }}
    >
      <img src={icon} alt="" width={32} height={32} />

      <h6 className="ms-3 mb-0 text-start">{text}</h6>
    </div>
  );
};
