import React, { useEffect, useState } from "react";
import ACTIVE_EASY from "../../assets/active/easy.svg";
import ACTIVE_FIRE from "../../assets/active/fire.svg";
import ACTIVE_WHISTLE from "../../assets/active/whistle.svg";
import EASY from "../../assets/easy.svg";
import smilingFace from "../../assets/face-smiling.png";
import FIRE from "../../assets/fire.svg";
import WHISTLE from "../../assets/whistle.svg";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

export const PartThreeSix = () => {
  const initialData = [
    {
      icon: EASY,
      text: "Easy Enough",
      slogan: "Small changes add up to big results!",
      description:
        "We will build your custom-fit workout and keep track of your progress every day!",
      select: false,
      activeIcon: ACTIVE_EASY,
    },
    {
      icon: FIRE,
      text: "Simple But a Little Sweaty",
      slogan: "Every drop counts!",
      description:
        "We have a clear plan to progressively get you closer to reaching your goals.",
      select: false,
      activeIcon: ACTIVE_FIRE,
    },
    {
      icon: WHISTLE,
      text: "Somewhat Challenging",
      slogan: "You're on fire!",
      description:
        "We offer a broad suite of challenging yet achievable programs to build your fitness.",
      select: false,
      activeIcon: ACTIVE_WHISTLE,
    },
  ];
  const [selectedIndex, setSelectedIndex] = useState(initialData);
  const [isVisible, setIsVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
    const set = selectedIndex.every((item) => item.select !== true);
    setDisabled(set);
  }, [selectedIndex]);

  return (
    <MobileViewLayout>
      {" "}
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center">
              Choose your preferred workouts
            </h3>
          </div>
          {selectedIndex.map((e, i) => (
            <Tile
              slogan={e.slogan}
              key={i}
              icon={e.icon}
              text={e.text}
              selected={e.select}
              index={i}
              setSelected={setSelectedIndex}
              element={e}
              initialData={initialData}
            />
          ))}
          <button
            className="btn common-btn-color w-100 fw-bold mt-4"
            style={{ height: "70px" }}
            disabled={disabled}
            onClick={() => {}}
          >
            {" "}
            Next
          </button>
        </div>
      </div>
    </MobileViewLayout>
  );
};

const Tile = ({
  icon,
  text,
  selected,
  index,
  setSelected,
  element,
  initialData,
}) => {
  const handleSelect = () => {
    const updatedItems = [...initialData];

    updatedItems.forEach((item) => {
      if (item.text === element.text) {
        item.select = !element.select;
      } else {
        item.select = false;
      }
    });

    setSelected(updatedItems);
  };
  return (
    <>
      <div
        className={`bg-white d-flex align-items-center mt-2 ${
          selected ? "active-box" : ""
        }`}
        style={{
          height: "80px",
          width: "100%",
          borderRadius: "15px",
          padding: "20px 20px",
        }}
        onClick={() => {
          handleSelect(index);
        }}
      >
        <img
          src={selected ? element.activeIcon : icon}
          alt=""
          width={32}
          height={32}
        />

        <h6 className="p-0 m-0 ms-2 text-start">{text}</h6>
      </div>
      {element?.select && (
        <div
          className="d-flex align-items-start flex-column mt-2 selected-box"
          onClick={() => {
            handleSelect(index);
          }}
        >
          <div className="d-flex flex-row align-items-center justify-content-center">
            <img src={smilingFace} alt="" width={28} height={28} />
            <h6
              className="p-0 m-0 text-start fw-bold px-1"
              style={{ fontSize: "15px" }}
            >
              {element.slogan}
            </h6>
          </div>
          <p className="p-0 m-0 text-start" style={{ fontSize: "13px" }}>
            {element.description}
          </p>
        </div>
      )}
    </>
  );
};
