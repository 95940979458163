import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PREDICTION from "../../assets/prediction.png";
import "../../assets/styles/main.css";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

const PartTwoScreenSeven = ({ bodyDetails }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  const new_date = moment(new Date(), "DD-MM-YYYY").add("days", 14);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  return (
    <MobileViewLayout>
      {" "}
      <>
        <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
          <div
            className="text-center main-padding d-flex flex-column"
            style={{
              minHeight: "100dvh",
              paddingTop: "18px",
              paddingBottom: "45px",
            }}
          >
            <div style={{ flex: 1 }}>
              <div className="prediction-wrapper">
                <span
                  className=" mt-4 text-center"
                  style={{ fontSize: "20px", color: "#6C6E79" }}
                >
                  We anticipate you reaching
                </span>
                <div className="prediction-value">
                  <span className="prediction-weight ">{`${
                    bodyDetails.testData.desire.weight
                  } ${
                    bodyDetails.testData.desire.unit == "KG" ? "kg" : "lbs"
                  }`}</span>{" "}
                  by{" "}
                  <span className="prediction-date ">
                    {moment(new_date).format("MMM DD")}
                  </span>
                </div>
              </div>
              <div className="prediction-img-main">
                <div className="img-div">
                  <img className="prediction-img" src={PREDICTION} alt="" />
                  <p className="img-date">
                    {moment(new_date).format("MMM DD")}
                  </p>
                </div>
                <div className=" d-flex justify-content-between date-container">
                  <p className="prediction-today">Today</p>
                  <p className="prediction-today-date">
                    {moment(new_date).format("MMM DD")}
                  </p>
                </div>
              </div>
              <div className="prediction-great-main">
                <span className="prediction-great">Awesome!</span>
                <p className="prediction-text">
                  We are getting a know you and your body better.
                </p>
              </div>
            </div>

            <button
              className="btn common-btn-color w-100 fw-bold"
              style={{ height: "70px", marginBottom: "20px" }}
              onClick={() => {
                navigate(RoutesName.partThreeIntro);
              }}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </>
    </MobileViewLayout>
  );
};

export default PartTwoScreenSeven;
