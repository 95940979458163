import React from "react";
import { useNavigate } from "react-router-dom";
import { RoutesName } from "../../utils/RoutesName";
import BOT from "../../assets/bot.gif";
import { useState } from "react";
import { useEffect } from "react";
import { Loader } from "../../widgets/Loader";
import { Footer } from "../../widgets/Footer";
import ApiService from "../../service/api";
import { showErrorToast, showSuccessToast } from "../../utils/ToastsModal";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

export const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (email.includes("@") && email.includes(".") && email !== "") {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }, [email]);

  const handleLogin = async () => {
    setLoading(true);
    await ApiService.loginUser({ email: email })
      .then((response) => {
        if (response.status === 200) {
          showSuccessToast(response.data?.message);
          localStorage.setItem("email", email);
          navigate(RoutesName.otp);
        }
        setLoading(false);
      })
      .catch((error) => {
        showErrorToast(error?.request?.response);
        setLoading(false);
      });
  };

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column pb-4"
          style={{ minHeight: "100dvh" }}
        >
          <div
            style={{ flex: 1 }}
            className="d-flex flex-column justify-content-center text-center align-items-center"
          >
            <img src={BOT} width={122} alt="" />
            <h2 className="fw-bold mt-0">Eazy Fit</h2>
            <h6 className="mt-3 w-100">
              Enter your registered email address to enter the app
            </h6>
            <input
              onFocus={() => setEmailFocus(true)}
              type="email"
              className={`input-field w-100 fs-6 p-2 mt-3 ${
                emailFocus && emailError ? "border-red" : ""
              }`}
              placeholder="Email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
            />
            <div className="text-start w-100 mt-1">
              <h6
                className=" p-0 small fw-light "
                style={{ color: "red", fontSize: "12px" }}
              >
                {emailFocus && emailError ? " please enter valid email" : ""}
              </h6>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <button
                  className="w-100 main-button py-3 fw-bold text-white mt-4 mb-1 subscription-btn"
                  style={{ height: "60px" }}
                  onClick={handleLogin}
                >
                  Login
                </button>
              </>
            )}
          </div>
          <p
            className="mt-2 "
            style={{
              color: "#6C6E79",
              fontWeight: "regular",
              fontSize: "14px",
            }}
          >
            Not have an account?{" "}
            <span
              className="main-text text-decoration-underline"
              onClick={() => navigate(RoutesName.getPlan)}
            >
              Register
            </span>
          </p>

          <Footer />
        </div>
      </div>
    </MobileViewLayout>
  );
};
