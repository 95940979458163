import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import AuthSlice from "../slices/AuthSlice";
import DetailsSlice from "../slices/DetailsSlice";
import { persistReducer } from "redux-persist";
import MealPlanSlice from "../slices/MealPlanSlice";

const detailPersistConfig = {
  key: "details",
  storage,
};
const reducers = combineReducers({
  authState: AuthSlice,
  mealState: MealPlanSlice,
  detailState: persistReducer(detailPersistConfig, DetailsSlice),
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
