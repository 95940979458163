import { useRecurly } from "@recurly/react-recurly";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import APPLE_PAY from "../../assets/apple-pay.svg";
import CARD_IMG from "../../assets/card-img.png";
import DISCOUNT from "../../assets/discount.png";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import ApiService from "../../service/api";
import { RoutesName } from "../../utils/RoutesName";
import { showErrorToast, showSuccessToast } from "../../utils/ToastsModal";
import { generateRandomCode } from "../../utils/modules";
import { Footer } from "../../widgets/Footer";
import { Loader } from "../../widgets/Loader";
import TimerNotification from "../../widgets/TimerNotification";
import Transformation from "../../widgets/Transformation";
import GatewayFeatures from "./GatewayFeatures";
import PayPalElement from "./PayPalElement";

const PaymentDuplicate = () => {
  const recurly = useRecurly();
  const navigate = useNavigate();
  const location = useLocation();
  const email = new URLSearchParams(location.search).get("email");
  const stateEmail = email || location?.state;
  const risk = recurly.Risk();
  const currentYear = moment().year().toString().slice(2, 4);

  const formRef = useRef();
  const emailRef = useRef();
  const cardNumberRef = useRef();
  const cardMonthRef = useRef();
  const cardYearRef = useRef();
  const cardCvvRef = useRef();

  const [tab, setTab] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("monthly-1");
  const [paypalPlan, setPaypalPlan] = useState("");
  const [unitPrice, setUnitPrice] = useState(0);
  const [cardNumber, setCardNumber] = useState("");
  const [cardMonth, setCardMonth] = useState("");
  const [cardYear, setCardYear] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cardNumberError, setCardNumberError] = useState(false);
  const [cardMonthError, setCardMonthError] = useState(false);
  const [cardYearError, setCardYearError] = useState(false);
  const [cardCvvError, setCardCvvError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSupport, setIsSupport] = useState(true);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const [appleAvailable, setAppleAvailable] = useState("");
  const [windowWidth, setWindowWidth] = useState(0);

  const nameString = stateEmail?.split("@")[0];
  const divideStringIntoHalf = () => {
    const length = nameString?.length;
    const middle = Math.floor(length / 2);

    const firstHalf = nameString?.slice(0, middle);
    setFirstName(firstHalf);
    const secondHalf = nameString?.slice(middle);
    setLastName(secondHalf);
  };

  useEffect(() => {
    !stateEmail && navigate(RoutesName.getPlan);
    divideStringIntoHalf();
  }, [stateEmail]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    const fetchPlans = async () => {
      await ApiService.getRecurlyPrice().then(async (response) => {
        if (response.status === 200) {
          const priceList = response?.data?.data?.plan_list;
          const currentUnitPrice =
            priceList?.length > 0 &&
            priceList?.find((plans) => plans.plan_code === currentPlan);
          setUnitPrice(currentUnitPrice?.unit_amount);
          setPaypalPlan(currentUnitPrice?.paypal_code);
          setCurrentPlan(currentUnitPrice?.plan_code);
        }
      });
    };
    fetchPlans();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTags = () => {
    ApiService.removeTag("4462802", {
      api_secret: process.env.REACT_APP_CK_SECRET_KEY,
      email: stateEmail.toLowerCase(),
    }).then(() => {
      ApiService.addTag("4462715", {
        api_secret: process.env.REACT_APP_CK_SECRET_KEY,
        email: stateEmail.toLowerCase(),
      });
    });
  };

  const handleCardNumberChange = (e) => {
    const inputVal = e.target.value;
    const inputCardNumber = e.target.value.replace(/\D/g, "");
    const formattedCardNumber = inputCardNumber
      .replace(/(\d{4})/g, "$1 ")
      .trim();
    setCardNumber(formattedCardNumber);
    const checkVal = inputVal.split(" ").join("");
    const length = checkVal.length;

    !inputVal || length < 15 || length > 16
      ? setCardNumberError(true)
      : setCardNumberError(false);

    if (length === 16) {
      cardMonthRef.current.focus({ preventScroll: true });
    }
  };

  const handleCardMonthChange = (e) => {
    const length = e.target.value.length;
    setCardMonth(e.target.value);
    length < 2 || e.target.value > 12
      ? setCardMonthError(true)
      : setCardMonthError(false);
    if (length === 2 && e.target.value <= 12) {
      cardYearRef.current.focus({ preventScroll: true });
    }
  };

  const handleCardYearChange = (e) => {
    const length = e.target.value.length;
    setCardYear(e.target.value);
    length < 2 || e.target.value < currentYear
      ? setCardYearError(true)
      : setCardYearError(false);
    if (length === 2 && e.target.value >= currentYear) {
      cardCvvRef.current.focus({ preventScroll: true });
    }
  };

  const handleCardCvvChange = (e) => {
    const length = e.target.value.length;
    setCardCvv(e.target.value);
    length < 3 ? setCardCvvError(true) : setCardCvvError(false);
    if (length === 3) {
      setCardCvvError(false);
    }
  };

  const handleThreeDSPayment = async (formData) => {
    setLoading(true);
    try {
      await ApiService.createSubscription(formData)
        .then(async (response) => {
          if (response.status === 200) {
            await ApiService.loginUser({
              email: stateEmail.toLowerCase(),
            })
              .then((res) => {
                if (res.status === 200) {
                  handleTags();
                  localStorage.setItem("email", stateEmail.toLowerCase());
                  showSuccessToast(res?.data?.message);
                  navigate(RoutesName.otp, {
                    state: "NewRegistered",
                  });
                  setLoading(false);
                }
              })
              .catch((err) => {
                showErrorToast(err?.response?.data);
                setLoading(false);
              });
          }
        })
        .catch((error) => {
          showErrorToast(
            error?.response?.data?.error ??
              error?.response?.data?.message ??
              error?.response?.data ??
              "Something went wrong"
          );
          setLoading(false);
        });
    } catch (e) {
      console.log(e.err);
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let showErr = false;
    if (!recurly && showErr) {
      return;
    }
    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("number", cardNumber);
    formData.append("month", cardMonth);
    formData.append("year", cardYear);
    formData.append("cvv", cardCvv);
    formData.append("email", stateEmail);
    formData.append("three_d_secure_action_required", true);
    formData.append("key", "ewr1-N0KmqIoNJcNjO0nHzgrLeR");

    await ApiService.getRecurlyToken(formData)
      .then((response) => {
        if (
          !cardNumberError &&
          !cardYearError &&
          !cardMonthError &&
          !cardCvvError &&
          !response.message
        ) {
          setLoading(true);
          const code = generateRandomCode();
          const token = response?.data?.id;
          if (token) {
            try {
              ApiService.createSubscription({
                email: stateEmail.toLowerCase(),
                token: token,
                code: code,
                plan_code: currentPlan,
                payment_type: "recurly",
              })
                .then(async (response) => {
                  if (response.status === 201) {
                    setLoading(true);
                    const threeDSecure = risk.ThreeDSecure({
                      actionTokenId: response?.data?.action_token_id,
                    });
                    threeDSecure.on("error", (err) => {
                      setLoading(false);
                      console.log("threeDSecure err", err);
                      showErrorToast("Payment Rejected");
                    });

                    threeDSecure.on("token", (threeDsToken) => {
                      setLoading(true);
                      handleThreeDSPayment({
                        email: stateEmail.toLowerCase(),
                        token: token,
                        plan_code: currentPlan,
                        three_d_secure_action_result_token_id: threeDsToken?.id,
                        code: code,
                        accountCode: response?.data?.accountCode,
                        accountId: response?.data?.accountId,
                        payment_type: "recurly",
                      });
                    });

                    threeDSecure.attach(document.getElementById("3D-Secure"));
                  }
                  if (response.status === 200) {
                    await ApiService.loginUser({
                      email: stateEmail.toLowerCase(),
                    })
                      .then((res) => {
                        if (res.status === 200) {
                          handleTags();
                          localStorage.setItem("email", stateEmail);
                          showSuccessToast(res?.data?.message);
                          navigate(RoutesName.otp, {
                            state: "NewRegistered",
                          });
                          setLoading(false);
                        }
                      })
                      .catch((err) => {
                        showErrorToast(err?.response?.data);
                        setLoading(false);
                      });
                  }
                })
                .catch((error) => {
                  showErrorToast(
                    error?.response?.data?.error ??
                      error?.response?.data?.message ??
                      error?.response?.data ??
                      "Something went wrong"
                  );
                  setLoading(false);
                });
            } catch (e) {
              setLoading(false);
              console.log(e.err);
            }
          }
        } else {
          if (
            response?.message ||
            (!cardNumberError &&
              !cardYearError &&
              !cardMonthError &&
              !cardCvvError)
          ) {
            showErrorToast("Card number cannot be a test card number");
          }
          setLoading(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    const applePayOptions = {
      requiredBillingAddressFields: ["all"],
      requiredShippingContactFields: ["phone", "email", "postalAddress"],
      countryCode: "US",
    };

    recurly.configure({
      applePay: applePayOptions,
    });
  }, [recurly]);

  useEffect(() => {
    if (!window.ApplePaySession) {
      if (appleAvailable !== "Not Available") {
        setIsSupport(false);
        setAppleAvailable("Not Available");
      }
      return;
    }
  }, [isPaymentProcessing]);

  const sendData = async (token) => {
    setLoadingPayment(true);
    const code = generateRandomCode();

    try {
      await ApiService.createSubscription({
        email: stateEmail.toLowerCase(),
        token: token.id,
        code: code,
        plan_code: currentPlan,
        payment_type: "recurly",
      })
        .then(async (response) => {
          if (response.status === 200) {
            await ApiService.loginUser({
              email: stateEmail.toLowerCase(),
            })
              .then((res) => {
                if (res.status === 200) {
                  handleTags();
                  localStorage.setItem("email", stateEmail);
                  showSuccessToast(res?.data?.message);
                  navigate(RoutesName.otp, {
                    state: "NewRegistered",
                  });
                  setLoadingPayment(false);
                }
              })
              .catch((err) => {
                showErrorToast(err?.response?.data);
                setLoadingPayment(false);
              });
          }
        })
        .catch((error) => {
          showErrorToast(error?.response?.data);
          setLoadingPayment(false);
        });
    } catch (e) {
      setLoadingPayment(false);
    }
    setPaymentProcessing(false);
    setLoadingPayment(false);
  };

  const applePay = recurly.ApplePay({
    country: "US",
    currency: "USD",
    label: "EazyFit.app",
    total: `${unitPrice}`,
  });

  const handleAppleClick = (event) => {
    event.preventDefault();
    setTab(false);
    if (!isSupport) {
      showErrorToast(
        "Your device or browser is not compatible with Apple Pay."
      );
      return;
    } else if (isSupport && applePay && stateEmail) {
      applePay?.ready(function () {
        applePay?.begin();
      });

      applePay.on("error", function (err) {
        console.error("Apple Pay error", err);
      });

      applePay.on("token", async function (token) {
        await sendData(token);
      });
    }
  };

  const handleOpen = (event) => {
    event.preventDefault();
    setTab(true);
  };

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const section = document.querySelector(hash);

      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <MobileViewLayout>
        <>
          <TimerNotification />
          <div className={``}>
            <div
              className="text-center main-padding d-flex flex-column pb-4"
              style={{
                minHeight: "100dvh",
                paddingTop: "74px",
                backgroundColor: "white",
              }}
            >
              <Transformation />
              <h3
                className="m-0 mt-4 p-0 fw-bold"
                style={{ fontSize: "20px", color: "#2680EB" }}
              >
                YOUR WORKOUT PLAN IS READY!
              </h3>
              <p
                className="mb-4 p-0"
                style={{ fontSize: "15px", fontWeight: "500" }}
              >
                It was designed to help you reach your goals
              </p>
              <div
                className="mb-4"
                style={{ borderRadius: 10, backgroundColor: "#2680eb" }}
              >
                <div style={{ position: "relative" }}>
                  <img src={CARD_IMG} className="w-100" alt="" srcSet="" />
                  <p
                    style={{
                      fontSize: "14px",
                      zIndex: 1999,
                      position: "absolute",
                      top: 10,
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                    className="rounded-5 bg-main px-3 py-1 text-white fw-bold"
                  >
                    Special Offer
                  </p>
                  <img
                    src={DISCOUNT}
                    alt=""
                    style={{
                      zIndex: "1999",
                      position: "absolute",
                      bottom: "0px",
                      transform: "translate(0 -50%) !important",
                      height: "auto",
                      width: "100%",
                      maxWidth: "160px",
                      margin: "auto",
                      left: 0,
                      right: 0,
                      top: "35px",
                      textAlign: "center",
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center text-white px-3 py-2 mt-1">
                  <p className="m-0" style={{ fontSize: "14px" }}>
                    {/* {planList[0]?.name} */}
                    7-Day Trial
                  </p>
                  <div className="d-flex align-items-center">
                    <p
                      className="m-0 text-decoration-line-through"
                      style={{ fontSize: "14px" }}
                    >
                      $9.99
                    </p>
                    &nbsp;
                    <p
                      className="m-0 fw-bold ps-2"
                      style={{ fontSize: "14px" }}
                    >
                      $1.00
                    </p>
                  </div>
                </div>
                <hr
                  style={{ width: "91%", color: "#fff" }}
                  className="mx-auto m-0"
                />
                <div className="d-flex justify-content-between align-items-center text-white px-3 py-2">
                  <p className="m-0 fw-bold" style={{ fontSize: "14px" }}>
                    Today Total
                  </p>

                  <p className="m-0 fw-bold" style={{ fontSize: "14px" }}>
                    $1.00
                  </p>
                </div>
              </div>

              <form
                onSubmit={handleSubmit}
                ref={formRef}
                className="stripe-form"
              >
                <div className="text-start mt-3" style={{ display: "none" }}>
                  <p
                    className="m-0 text-14"
                    style={{ color: "#6C6E79", fontWeight: 600 }}
                  >
                    EMAIL
                  </p>
                  <input
                    ref={emailRef}
                    className={` input-field ${
                      emailError ? "border-error" : "border-green"
                    } w-100 mt-1 p-2`}
                    id="email"
                    name="email"
                    type="text"
                    placeholder=""
                    required
                    defaultValue={stateEmail}
                    data-recurly={"email"}
                    style={{ textTransform: "lowercase" }}
                  />
                  {emailError ? (
                    <p className="small" style={{ color: "red" }}>
                      Enter valid email address
                    </p>
                  ) : null}
                </div>
                {tab && (
                  <div className={`mt-2 mb-3 card-container`}>
                    <div className="text-start">
                      <p className="m-0 text-14 card-label">CARD NUMBER</p>
                      <input
                        ref={cardNumberRef}
                        className={` input-field ${
                          cardNumberError ? "border-error" : "border-green"
                        } w-100 mt-1 p-2 bg-white`}
                        id="cardNumber"
                        name="cardNumber"
                        type="text"
                        placeholder="XXXX XXXX XXXX XXXX"
                        required
                        value={cardNumber}
                        data-recurly={"number"}
                        onChange={(event) => handleCardNumberChange(event)}
                        inputMode="numeric"
                        autoComplete="off"
                        style={{ fontFamily: "Helvetica" }}
                      />
                    </div>

                    <div className="d-flex mt-4 justify-content-between">
                      <div
                        className="text-start"
                        style={{ minWidth: "33%", width: "55%" }}
                      >
                        <p className="m-0 text-14 card-label">EXPIRES ON</p>

                        <div className="d-flex">
                          <input
                            ref={cardMonthRef}
                            className={` input-field ${
                              cardMonthError ? "border-error" : "border-green"
                            } w-50 mt-1 p-2 bg-white me-1`}
                            id="cardMonth"
                            name="cardMonth"
                            type="text"
                            placeholder="MM"
                            required
                            value={cardMonth}
                            data-recurly={"month"}
                            onChange={(event) => handleCardMonthChange(event)}
                            inputMode="numeric"
                            autoComplete="off"
                            style={{ fontFamily: "Helvetica" }}
                            maxLength={2}
                          />
                          <input
                            ref={cardYearRef}
                            className={` input-field ${
                              cardYearError ? "border-error" : "border-green"
                            } w-50 mt-1 p-2 bg-white ms-1`}
                            id="cardYear"
                            name="cardYear"
                            type="text"
                            placeholder="YY"
                            required
                            value={cardYear}
                            data-recurly={"year"}
                            onChange={(event) => handleCardYearChange(event)}
                            inputMode="numeric"
                            autoComplete="off"
                            style={{ fontFamily: "Helvetica" }}
                            maxLength={2}
                          />
                        </div>
                      </div>
                      <div className="text-start" style={{ width: "30%" }}>
                        <p className="m-0 text-14 card-label">CVV</p>
                        <input
                          ref={cardCvvRef}
                          className={` input-field ${
                            cardCvvError ? "border-error" : "border-green"
                          } w-100 mt-1 p-2 bg-white`}
                          id="cardYear"
                          name="cardYear"
                          type="text"
                          placeholder="CVV"
                          required
                          value={cardCvv}
                          data-recurly={"cvv"}
                          onChange={(event) => handleCardCvvChange(event)}
                          inputMode="numeric"
                          autoComplete="off"
                          style={{ fontFamily: "Helvetica" }}
                          maxLength={4}
                        />
                      </div>
                      <input
                        type="hidden"
                        name="recurly-token"
                        data-recurly="token"
                      />
                    </div>
                  </div>
                )}
                {!tab ? (
                  <button
                    className="w-100 main-button fw-bold fs-5 text-white mt-1 mb-4"
                    style={{ height: "60px", backgroundColor: "#009B24" }}
                    onClick={handleOpen}
                  >
                    Credit<span style={{ fontWeight: "300" }}>/</span>Debit Card
                  </button>
                ) : isLoading ? (
                  <Loader className="align-self-center mt-1 mb-4" />
                ) : (
                  <button
                    type="submit"
                    className="w-100 main-button py-3 fw-bold fs-5 text-white mt-1 mb-4"
                    style={{ height: "60px", backgroundColor: "#009B24" }}
                  >
                    Process Card
                  </button>
                )}
                {loadingPayment ? (
                  <Loader className="align-self-center mt-2 mb-3" />
                ) : (
                  <button
                    id="apple-pay"
                    onClick={handleAppleClick}
                    className="w-100 main-button d-flex justify-content-center align-items-center fs-5 text-white mb-4 custom-payment-btn"
                  >
                    <img src={APPLE_PAY} alt="" />
                  </button>
                )}
                <PayPalElement email={stateEmail} plan={paypalPlan} />
              </form>
              <div
                id="3D-Secure"
                className="threeDSecure"
                style={{
                  maxWidth: "390px",
                }}
              ></div>
              <GatewayFeatures />
            </div>
          </div>
        </>
      </MobileViewLayout>
      <hr />
      <Footer />
    </>
  );
};

export default PaymentDuplicate;
