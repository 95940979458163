import React, { useEffect, useRef, useState } from "react";
import Arrow from "../../assets/arrow-left.svg";
import { Footer } from "../../widgets/Footer";
import SUPPORT from "../../assets/support.svg";
import TERMS from "../../assets/terms-policy.svg";
import MANAGE from "../../assets/manage.svg";
import LOGOUT from "../../assets/logout.svg";
import Menu from "../../widgets/Menu";
import { RoutesName } from "../../utils/RoutesName";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutState } from "../../slices/AuthSlice";
import ActionSheet from "actionsheet-react";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

export const ManageSubscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  const handleSupportClick = () => {
    const email = "support@eazyfit.app";
    const subject = "Help & Support";

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

    window.location.href = mailtoLink;
  };

  const actionData = [
    {
      id: 1,
      title: "Support",
      image: SUPPORT,
      handleClick: handleSupportClick,
    },
    {
      id: 2,
      title: "Terms of Service",
      image: TERMS,
      handleClick: () => {
        window.scrollTo(0, 0);
        navigate(RoutesName.terms);
      },
    },
    {
      id: 3,
      title: "Privacy Policy",
      image: TERMS,
      handleClick: () => {
        window.scrollTo(0, 0);
        navigate(RoutesName.privacy);
      },
    },
    {
      id: 4,
      title: "Manage Subscription",
      image: MANAGE,
      handleClick: () => {},
    },
    {
      id: 5,
      title: "Logout",
      image: LOGOUT,
      handleClick: () => {
        dispatch(logoutState());
        navigate(RoutesName.login);
      },
    },
  ];

  const handleOpen = () => {
    ref.current.open();
  };

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  return (
    <>
      <MobileViewLayout>
        {" "}
        <div
          className={`animate-from-right ${
            isVisible ? "slide-in" : ""
          } d-flex flex-column justify-content-between`}
          style={{
            backgroundColor: "#FFFFFF",
            overflow: "hidden",
          }}
        >
          <Menu onClick={handleOpen} />
          <hr />
          <div
            className="text-center main-padding d-flex flex-column"
            style={{
              paddingBottom: "18px",
              height: "calc(100% - 50px)",
              backgroundColor: "#FFFFFF",
            }}
          >
            <div
              className="d-flex justify-content-center"
              style={{ position: "relative" }}
            >
              <div className="">
                <img
                  src={Arrow}
                  alt=""
                  className=""
                  style={{
                    width: "25px",
                    height: "25px",
                    position: "absolute",
                    top: "2px",
                    left: "0",
                  }}
                  onClick={() => navigate(RoutesName.courses)}
                />
              </div>
              <div style={{}}>
                <p
                  className="fw-500"
                  style={{
                    fontSize: "20px",
                    color: "#000000",
                    textAlign: "center",
                  }}
                >
                  Manage Subscription
                </p>
              </div>
            </div>
            <button
              className=" subscription-btn w-100 fw-bold mt-4"
              style={{
                height: "50px",
                color: "white",
                border: "none",
                borderRadius: "10px",
              }}
              onClick={() => {}}
            >
              Active
            </button>

            <div
              style={{
                flex: "1",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p style={{ fontSize: "13px" }}>
                Do you want to cancel? <br /> Reach our friendly support team{" "}
                <a href="mailto:support@eazyfit.app?subject=Cancel%20Subscription">
                  here
                </a>
              </p>
            </div>
          </div>
        </div>
      </MobileViewLayout>
      <div
        style={{
          marginTop: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <hr style={{ width: "100%" }} />
        <Footer />
      </div>
      <ActionSheet
        ref={ref}
        sheetStyle={{
          backgroundColor: "#000000",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
        bgTransition="opacity 0.3s ease-in-out"
        sheetTransition="transform 0.3s ease-in-out"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: "15px 15px",
          }}
        >
          <ListMenu arrayData={actionData} />
        </div>
      </ActionSheet>
    </>
  );
};

const ListMenu = ({ arrayData }) => {
  return arrayData.map((d) => (
    <div
      className="d-flex flex-row mt-2 mb-2 align-items-center"
      key={d?.id}
      onClick={d?.handleClick}
    >
      <img className="mx-3" alt="" src={d?.image} height={30} width={30} />
      <p className="m-0 p-0 text-white" style={{ fontSize: "14px" }}>
        {d?.title}
      </p>
    </div>
  ));
};
