import React from "react";

export const Loader = ({ className }) => {
  return (
    <div
      className={"spinner-border spinner-border mt-3 " + className}
      style={{ color: "#e30000" }}
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};
