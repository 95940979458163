import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";
import ApiService from "../../service/api";

export const PartTwoScreenTwo = ({ bodyDetails, setBodyDetails }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const [placeholderVisible, setPlaceholderVisible] = useState(true);
  const [bmi, setBmi] = useState(0);


  const dispatch = useDispatch();

  const weightRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  const [number, setNumber] = useState();
  const [isKG, setIsKG] = useState(false);
  const [kgValue, setKgValue] = useState();

  useEffect(() => {
    if (isKG) {
      setNumber(Math.round(number * 0.45359237));
      setKgValue(Math.round(number * 0.45359237));
    } else {
      setNumber(Math.round(number / 0.45359237));
      setKgValue(Math.round(number / 0.45359237));
    }
  }, [isKG]);

  useEffect(() => {
    let kg = isKG ? number : Math.round(number * 0.45359237);
    let height = bodyDetails.height;

    let update = ((kg / (height * height)) * 10000).toFixed(2);
    setBmi(update);
  }, [number, bodyDetails.height, isKG]);

  useEffect(() => {
    if (location.state?.data) {
      let kgVar;
      if (location.state.data.weight.includes("kg")) {
        setKgValue(
          Math.round(
            location.state?.data.weight.replace(/\D/g, "") / 0.45359237
          )
        );
        setNumber(
          Math.round(
            location.state?.data.weight.replace(/\D/g, "") / 0.45359237
          )
        );
        kgVar = Math.round(
          location.state?.data.weight.replace(/\D/g, "") / 0.45359237
        );
        setIsKG(true);
      } else {
        setIsKG(false);
        setKgValue(location.state.data.weight.replace(/\D/g, ""));
        kgVar = location.state.data.weight.replace(/\D/g, "");
        setNumber(location.state.data.weight.replace(/\D/g, ""));
      }
      
       let height = bodyDetails.height;
       if (location.state.data.height.includes("cm")) {
         height =location.state.data.height.replace(" cm", "");
       } else {
         height = (
           parseInt(
             location.state.data.height.split(" ft ")[1].replace(" in ", "") ===
               "NaN"
               ? "0"
               : location.state.data.height.split(" ft ")[1].replace(" in ", "")
           ) *
             2.54 +
           parseInt(location.state.data.height.split(" ft ")[0]) * 30.48
         ).toFixed(0);
       }
      
      setBodyDetails({...bodyDetails,height:height})
    }
  }, []);

  const handleInputFocus = () => {
    setPlaceholderVisible(false);
  };

  const handleInputBlur = () => {
    setPlaceholderVisible(true);
  };

  const updateData = (value) => {
    ApiService.updateGoals({ weight: value })
      .then((res) => navigate(-1))
      .catch((err) => {
        console.log(err);
        navigate(-1);
      });
  };

  const handleNextClick = () => {
    setBodyDetails({
      ...bodyDetails,
      currentWeight: !isKG
        ? isNaN(kgValue)
          ? 0
          : kgValue * 0.45359237
        : isNaN(kgValue)
        ? 0
        : kgValue,
      testData: {
        ...bodyDetails.testData,
        current: {
          weight: number,
          unit: isKG ? "KG" : "LBS",
        },
      },
    });

    if (kgValue || !isNaN(kgValue)) {
      dispatch(
        setDetailsData({
          weight: !isKG ? `${kgValue} lbs` : `${kgValue} kg`,
        })
      );
      location.state?.data
        ? updateData(!isKG ? `${kgValue} lbs` : `${kgValue} kg`)
        : navigate(RoutesName.partTwoGoalWeight);
    }
  };

  const inputLength = number && !isNaN(number) ? String(number)?.length : 1;

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "15px",
            paddingBottom: "45px",
          }}
        >
          <div>
            <h3 className="fw-medium mt-4 text-center heading-font">
              Your current weight
            </h3>
            <div className="mt-4 d-flex justify-content-center">
              <div className="p-0 border new-border rounded-5 d-flex">
                <h5
                  onClick={() => setIsKG(false)}
                  className={
                    isKG
                      ? "px-3 m-0 py-1"
                      : "new-color rounded-5 px-4 m-0 text-white py-1"
                  }
                >
                  lbs
                </h5>
                <h5
                  onClick={() => setIsKG(true)}
                  className={
                    !isKG
                      ? "px-3 m-0 py-1"
                      : "new-color rounded-5 px-4 m-0 text-white py-1"
                  }
                >
                  kg
                </h5>
              </div>
            </div>
            <div
              className="mt-1 mb-1"
              style={{
                position: "relative",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="d-flex flex-row align-items-center justify-content-center">
                <input
                  placeholder={placeholderVisible ? "0" : ""}
                  maxLength={3}
                  ref={weightRef}
                  type="text"
                  className="my-auto"
                  value={number && number !== "NaN" ? number : ""}
                  onChange={(e) => {
                    setKgValue(e.target.value);
                    setNumber(e.target.value);
                  }}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  style={{
                    height: "auto",
                    fontWeight: "700",
                    border: "none",
                    backgroundColor: "rgb(247, 247, 247)",
                    fontSize: "50px",
                    outline: "none",
                    padding: "0px",
                    paddingRight: 0,
                    textAlign: "right",
                    width: `calc(${inputLength}ch`,
                    maxWidth: "99%",
                  }}
                  name=""
                  id=""
                  inputMode="numeric"
                />
                <h5
                  className="fw-bold custom-cm-input"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginTop: "20px",
                    marginBottom: "0px",
                    lineHeight: "100px",
                  }}
                >
                  {isKG ? "kg" : "lbs"}
                </h5>
              </div>
              <div
                className="d-flex align-items-end"
                style={{ overflowX: "hidden" }}
              ></div>
            </div>
          </div>
          <div className="w-100 rounded-3 mb-2 bg-white p-2 d-flex align-items-center">
            <div className="inline-block2 bmi_outer">
              <h6 className="fw-bold bmi_title">Your BMI:</h6>
              <h3 className="fw-bold text-danger px-3 pt-1">
                {bodyDetails.height != 0 && bmi !== "NaN" ? bmi : ""}
              </h3>
            </div>
            <p
              style={{ fontSize: "14px", textAlign: "left" }}
              className="m-0 ms-2 p-0 text-secondary"
            >
              Just a bit more sweat for a fitter you!
            </p>
          </div>
          <button
            className={`btn w-100 fw-bold mt-2 ${
              location.state?.data ? "profile-button-bg" : "common-btn-color"
            }`}
            style={{ height: "70px" }}
            onClick={handleNextClick}
          >
            {location.state?.data ? "Save" : "Next"}
          </button>
        </div>
      </div>
    </MobileViewLayout>
  );
};
