import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import goals from "../../assets/goal-prediction.png";
import "../../assets/styles/main.css";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

const PartThreeThree = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  return (
    <MobileViewLayout>
      {" "}
      <>
        <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
          <div
            className="text-center main-padding d-flex flex-column"
            style={{
              minHeight: "100dvh",
              paddingTop: "45px",
              paddingBottom: "45px",
            }}
          >
            <div style={{ flex: 1 }}>
              <h3 className="fw-medium mt-4 text-center">
                You have great potential to crush your goals!
              </h3>
              <img
                className="prediction-img"
                src={goals}
                alt=""
                style={{ marginTop: "83px", marginBottom: "70px" }}
              />
              <div
                className="prediction-great-main"
                style={{ marginBottom: "10px" }}
              >
                <p className="prediction-text">
                  Based on EazyFit's historical data.{" "}
                  <span className="fw-bold text-dark">
                    For women in their 20s
                  </span>
                  , weight loss is usually delayed at first, but after 7 days
                  you can burn off calories like crazy!
                </p>
              </div>
            </div>

            <button
              className="btn common-btn-color w-100 fw-bold mt-"
              style={{ height: "70px" }}
              onClick={() => {
                navigate(RoutesName.partThreeFour);
              }}
            >
              {" "}
              Next
            </button>
          </div>
        </div>
      </>
    </MobileViewLayout>
  );
};

export default PartThreeThree;
