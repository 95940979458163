import React from "react";
import MENU_IMG from "../assets/menu-icon.svg";
import PROFILE_IMG from "../assets/tab-header-images/profile.png";

const Menu = ({ menuClick, profileClick }) => {
  return (
    <div
      className="d-flex flex-row align-items-center justify-content-between pt-3 main-padding-auth w-100"
      style={{
        height: "4svh",
        maxHeight: "80px",
        alignSelf: "center",
      }}
    >
      <img
        src={MENU_IMG}
        alt=""
        width={25}
        height={20}
        className=""
        onClick={() => menuClick()}
        style={{ cursor: "pointer" }}
      />
      <img
        src={PROFILE_IMG}
        alt=""
        width={25}
        height={25}
        className=""
        onClick={() => profileClick()}
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};

export default Menu;
