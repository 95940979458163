import React, { useEffect, useRef, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FaRegCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import arrowBack from "../../assets/arrow-left.svg";
import emailIcon from "../../assets/icon-email.svg";
import lockIcon from "../../assets/icon-lock.svg";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import ApiService from "../../service/api";
import { setDetailsData } from "../../slices/DetailsSlice";
import { RoutesName } from "../../utils/RoutesName";
import { showErrorToast } from "../../utils/ToastsModal";
import { Footer } from "../../widgets/Footer";

const GetPlan = () => {
  const navigate = useNavigate();
  const emailRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorFocus, setEmailErrorFocus] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const { details } = useSelector((state) => state.detailState);

  const validateEmail = (email) => {
    var pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return pattern.test(email);
  };

  const handleSubmit = async (e) => {
    if (!validateEmail(email)) {
      emailRef.current.focus();
      setEmailError(true);
      setEmailErrorFocus(true);
      setSuccess(false);
    } else {
      setSuccess(true);
      const apiData = { ...details, email: email };
      await ApiService.addEmail(apiData)
        .then((response) => {
          if (response.status === 200) {
            ApiService.addTag("4462802", {
              api_secret: process.env.REACT_APP_CK_SECRET_KEY,
              email: email,
            });
            dispatch(setDetailsData(apiData));
            setTimeout(() => {
              navigate(RoutesName.payment, { state: email });
            }, 1000);
          }
        })
        .catch((err) => {
          showErrorToast(err?.response?.data);
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  useEffect(() => {
    if (validateEmail(email) && emailErrorFocus) {
      setEmailError(false);
    } else if (emailErrorFocus) {
      setEmailError(true);
      emailRef.current.focus();
    }
  }, [email]);

  return (
    <>
      <MobileViewLayout>
        <div
          className={`animate-from-right ${
            isVisible ? "slide-in position-relative" : ""
          } d-flex flex-column justify-content-between`}
          style={{
            transform: isVisible && "translateY(0)",
            // minHeight: "100dvh",
            backgroundColor: "#FFFFFF",
          }}
        >
          <div
            className="text-center main-padding d-flex flex-column"
            style={{
              paddingTop: "50px",
              height: "calc(100% - 50px)",
              backgroundColor: "#FFFFFF",
            }}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ position: "relative" }}
            >
              <div>
                <img
                  src={arrowBack}
                  alt=""
                  className=""
                  style={{
                    width: "25px",
                    height: "25px",
                    position: "absolute",
                    left: "0",
                    top: "5px",
                  }}
                  onClick={() => navigate(-1)}
                />
              </div>
              <div>
                <p className="plan-text-big">Your Personal Plan</p>
              </div>
            </div>
            <HelmetProvider>
              <Helmet>
                <script>
                  {`
         !function(f,b,e,v,n,t,s)
         {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
         n.callMethod.apply(n,arguments):n.queue.push(arguments)};
         if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
         n.queue=[];t=b.createElement(e);t.async=!0;
         t.src=v;s=b.getElementsByTagName(e)[0];
         s.parentNode.insertBefore(t,s)}(window, document,'script',
         'https://connect.facebook.net/en_US/fbevents.js');
         fbq('init', '392797813106209');
         fbq('track', 'InitiateCheckout');
       `}
                </script>
                <noscript>
                  {`
         <img
         height="1"
         width="1"
         style={{ display: 'none' }}
         src="https://www.facebook.com/tr?id=392797813106209&ev=InitiateCheckout&noscript=1"
         alt="Facebook Pixel"
       />
       `}
                </noscript>
              </Helmet>
            </HelmetProvider>
            <p className="plan-text-big mb-0">
              Enter your email to get your personal{" "}
              <span style={{ color: "#2680EB" }}>EazyFit</span> plan!
            </p>

            <div className="d-flex justify-content-center align-items-center flex-column mt-4">
              <label className="email-input-label">YOUR EMAIL</label>
              <div className="email-input-wrapper">
                <div className="email-input-icon">
                  <img
                    src={emailIcon}
                    alt=""
                    className=""
                    style={{
                      width: "17px",
                      height: "14px",
                    }}
                  />
                </div>
                <input
                  ref={emailRef}
                  required
                  className="input-field email-input"
                  placeholder="Your Email"
                  onChange={(e) => {
                    setSuccess(false);
                    setEmail(e.target.value.toLowerCase());
                  }}
                  style={{ textTransform: email.length > 0 && "lowercase" }}
                />
                <FaRegCheckCircle
                  className={`email-input-icon success-check-icon ${
                    success && "fade-in"
                  }`}
                  color="#009B24"
                />
              </div>
              {emailError && (
                <p className="small align-self-start" style={{ color: "red" }}>
                  Enter valid email address
                </p>
              )}
              {!emailError && (
                <p
                  className={`small align-self-start ${success && "fade-in"}`}
                  style={{
                    color: "#009B24",
                    fontWeight: "500",
                    opacity: 0,
                    transition: "opacity 0.2s ease-in-out",
                  }}
                >
                  Well done!
                </p>
              )}
              <div className="d-flex flex-row justify-content-around align-items-center w-100 mt-4 plan-privacy-text">
                <img src={lockIcon} alt="" />
                <p className="mb-0">
                  We respect your privacy. Your email can make the advertising
                  experience more relevant.{" "}
                  <span
                    style={{ textDecoration: "underline" }}
                    onClick={() => navigate(RoutesName.privacy)}
                  >
                    Privacy Policy
                  </span>
                  .
                </p>
              </div>
              <button
                className="w-100 main-button py-3 fw-bold text-white mt-4 mb-1 subscription-btn"
                style={{ height: "60px" }}
                onClick={handleSubmit}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </MobileViewLayout>
      <div style={{ marginTop: "auto" }}>
        <hr />
        <Footer />
      </div>
    </>
  );
};

export default GetPlan;
