import React, { useEffect, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import star from "../../assets/Trustpilot_ratings_5star-RGB.svg";
import "../../assets/styles/main.css";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

const PartFourFourteen = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (percent < 100) {
        setPercent(percent + 1);
      } else {
        setTimeout(() => {
          navigate(RoutesName.getPlan);
        }, 500);
      }
    }, 80);
  }, [percent]);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <MobileViewLayout>
      <>
        <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
          <div
            className="text-center main-padding d-flex flex-column"
            style={{
              minHeight: "100dvh",
              paddingTop: "30px",
              paddingBottom: "45px",
            }}
          >
            <div style={{ flex: 1 }}>
              <div className="progressbar-container">
                <CircularProgressbarWithChildren
                  strokeWidth={10}
                  value={percent}
                  styles={{
                    path: {
                      stroke: "#2680EB",
                      width: "100px",
                    },
                    text: {
                      fill: "#000000",
                      fontSize: "22px",
                      fontWeight: "bolder",
                    },
                  }}
                  className="custom-progress-bar"
                >
                  <div>
                    <strong style={{ fontSize: "50px" }}>{percent}</strong>
                    <span style={{ fontSize: "20px", fontWeight: "600" }}>
                      %
                    </span>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <div>
                <p
                  className="pt-3"
                  style={{
                    fontSize: "16px",
                    opacity: percent <= 70 && percent >= 67 ? 0 : 1,
                    transition: "opacity 0.5s ease-in-out",
                  }}
                >
                  {percent <= 70
                    ? "Creating your personalized plan"
                    : "Your personalized workout plan is ready"}
                </p>
                <hr />
                <h2
                  className="fw-bold"
                  style={{ fontSize: "25px", color: "#2680EB" }}
                >
                  5 million people
                </h2>
                <p style={{ fontSize: "17px" }}>have chosen EazyFit.app</p>
              </div>
              <Carousel autoPlay={true} arrows={false} responsive={responsive}>
                <div
                  className="carousel-inner p-3"
                  style={{
                    height: "170px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #D6D6D6",
                    borderRadius: "10px",
                  }}
                >
                  <div className="star-rating pb-3 ">
                    <img src={star} alt="" />
                  </div>
                  <div className="carousel-item active">
                    <div className="">
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <p
                          className="mb-0 "
                          style={{ fontSize: "14px", fontWeight: "600" }}
                        >
                          User-Friendly Workouts
                        </p>
                        <p style={{ fontSize: "12px", color: "#6C6E79" }}>
                          Lindsey
                        </p>
                      </div>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "#6C6E79",
                          textAlign: "start",
                        }}
                      >
                        The app offers user-friendly workouts that cater to
                        various fitness levels, making it accessible for both
                        beginners and experienced users.
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </>
    </MobileViewLayout>
  );
};

export default PartFourFourteen;
