import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FEMALE from "../../assets/female-img.png";
import BULB from "../../assets/light-bulb.png";
import MALE from "../../assets/male-img.png";
import "../../assets/styles/main.css";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

const PartThreeOne = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  return (
    <MobileViewLayout>
      {" "}
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center">What's your gender?</h3>
            <div className="d-flex px-1 align-items-center text-center justify-content-center gender-text-main">
              <img className="mx-2" src={BULB} alt="" />
              <p className="p-0 m-0 header-box-text ">
                This will help us calculate your basal metabolic rate and adapt
                to your personal plan.
              </p>
            </div>

            <div className="d-flex justify-content-around gender-img-main">
              <img
                className="gender-male mx-1"
                src={MALE}
                alt=""
                onClick={() => navigate(RoutesName.partThreeTwo)}
              />
              <img
                className="gender-female mx-1"
                src={FEMALE}
                alt=""
                onClick={() => navigate(RoutesName.partThreeTwo)}
              />
            </div>

            <div className="text-center gender-text">
              <span
                className=""
                onClick={() => navigate(RoutesName.partThreeTwo)}
              >
                Prefer not to say
              </span>
            </div>
          </div>
        </div>
      </div>
    </MobileViewLayout>
  );
};

export default PartThreeOne;
