import React from "react";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import BACK_ARROW from "../../assets/tab-header-images/back-arrow.svg";
import RIGHT_ARROW from "../../assets/tab-header-images/right-arrow.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ApiService from "../../service/api";
import { showErrorToast, showSuccessToast } from "../../utils/ToastsModal";
import { Loader } from "../../widgets/Loader";
import { toast } from "react-toastify";

const Profile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [details, setDetails] = React.useState({});
  const [error, setError] = React.useState(null);

  const profileData = [
    {
      title: "Body areas to work on",
      selectedItem: details.specificAreas
        ? details.specificAreas
        : "Not Selected",
      navigate: "/part-one/areas",
    },
    {
      title: "Your current weight",
      selectedItem: details.weight ? details.weight : "Not Selected",
      navigate: "/part-two/weight",
    },
    {
      title: "Your desired weight",
      selectedItem: details.desiredWeight
        ? details.desiredWeight
        : "Not Selected",
      navigate: "/part-two/goal-weight",
    },
    {
      title: "Favorite workout type",
      selectedItem: details.favoriteWorkOut
        ? details.favoriteWorkOut
        : "Not Selected",
      navigate: "/part-three/preferred-type",
    },
    {
      title: "Ideal spot for exercise",
      selectedItem: details.exerciseSpot
        ? details.exerciseSpot
        : "Not Selected",
      navigate: "/part-three/place",
    },
    {
      title: "Physical limitations",
      selectedItem: details.injuriesAreas
        ? details.injuriesAreas
        : "Not Selected",
      navigate: "/part-three/workout-injuries",
    },
  ];

  const handleUpdateClick = async () => {
    ApiService.updateMealPlanData(details)
      .then((response) => {})
      .catch((error) => {});

    navigate("/course", { state: { showToast: true } });
  };

  React.useEffect(() => {
    setLoading(true);
    ApiService.getGoalsList()
      .then((res) => {
        setLoading(false);
        setDetails(res.data.DietNeeds);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message || err);
      });
  }, []);

  return (
    <MobileViewLayout>
      <div
        className="d-flex flex-column justify-content-center   py-4"
        style={{ gap: "20px", minHeight: "100dvh" }}
      >
        {loading ? (
          <div className="w-100 d-flex  justify-content-center align-items-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="position-relative main-padding-auth d-flex align-items-center justify-content-center ">
              <div className="position-absolute start-0 main-padding-auth">
                <img
                  onClick={() => navigate(-1)}
                  src={BACK_ARROW}
                  alt=""
                  style={{ height: "27px", width: "27px", cursor: "pointer" }}
                />
              </div>
              <h3
                className="mb-0 text-center w-100"
                style={{ fontWeight: "600" }}
              >
                Plan setting
              </h3>
            </div>
            {error ? (
              <div>
                <p className="text-center py-5">Something went wrong</p>
              </div>
            ) : (
              <>
                <div>
                  {profileData.map((data, index) => (
                    <div
                      key={index}
                      className="border-bottom border-1 d-flex justify-content-between align-items-center py-3 border-diet-plan"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(data.navigate, {
                          state: {
                            data:
                              data.title === "Your current weight"
                                ? {
                                    weight: data.selectedItem,
                                    height: details.height,
                                  }
                                : data.selectedItem,
                          },
                        });
                      }}
                    >
                      <div className="main-padding-auth-left">
                        <p
                          className="fw-semibold m-0"
                          style={{ fontSize: "16px" }}
                        >
                          {data.title}
                        </p>
                        <p
                          className="fw-bold m-0"
                          style={{ color: "#2680EB", fontSize: "16px" }}
                        >
                          {data.selectedItem}
                        </p>
                      </div>
                      <div className="main-padding-auth-right">
                        <img
                          alt=""
                          src={RIGHT_ARROW}
                          style={{ height: "20px", width: "12px" }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="main-padding-auth">
                  <p
                    className="text-center mb-2"
                    style={{ color: "#6C6E79", fontSize: "14px" }}
                  >
                    A new plan will be created after saving
                  </p>
                  <button
                    className="btn w-100 py-3 fw-medium d-flex justify-content-center align-items-center  "
                    style={{
                      backgroundColor: "#2680EB",
                      color: "white",
                      fontSize: "18px",
                      borderRadius: "10px",
                      height: "70px",
                    }}
                    disabled={loading}
                    onClick={handleUpdateClick}
                  >
                    Update Plan
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </MobileViewLayout>
  );
};

export default Profile;
