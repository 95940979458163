import React, { useEffect, useState } from "react";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";

const Privacy = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center d-flex flex-column pb-4"
          style={{ minHeight: "100dvh", backgroundColor: "#F7F7F7" }}
        >
          <div className="d-flex justify-content-center bottom-border">
            <div style={{ position: "relative" }}>
              <div style={{ position: "absolute", left: "-40px" }}>
                <IoChevronBackCircleOutline
                  size={30}
                  onClick={() => navigate(-1)}
                />
              </div>
              <h4 className="m-0 p-0">Privacy and Policy</h4>
            </div>
          </div>
          <div
            style={{ textAlign: "left" }}
            className="d-flex flex-column main-padding mt-4"
          >
            <h3 className="mt-2">Our Commitment to Your Privacy</h3>
            <p>
              We respect and protect the privacy of our WebApp users. This
              Privacy Policy explains how we collect and use your information
              and is part of our Terms of Use when you use our WebApp. By using
              our WebApp and the service provided via the WebApp (“Service”),
              you consent to our Privacy Policy. If you don’t agree, please
              don’t use our WebApp.
            </p>
            <h3 className="mt-2">What information do we collect?</h3>
            <p>
              If you make purchases or buy a subscription, our third-party
              billing service providers will collect information such as your
              full name, credit card number, and billing address. If you email
              us, we may use your email address to reply to your email.
              Information Sent by Your Mobile Device. We collect certain
              information that your mobile device sends when you use our WebApp,
              like a device identifier, user settings and the operating system
              of your device, as well as information about your use of our
              Services. Location Information. When you use our WebApp, we may
              collect and store information about your location by converting
              your IP address into a rough geo-location or by accessing your
              mobile device’s GPS coordinates or coarse location if you enable
              location services on your device. We may use location information
              to improve and personalize our Services for you. If you do not
              want us to collect location information, you may disable that
              feature on your mobile device.
            </p>
            <h3 className="mt-2">Where do we store your information?</h3>
            <p>
              Your information may be stored in our own servers or in servers
              owned by third-party cloud storage providers. Third-party storage
              providers may not use your information except as provided in this
              Privacy Policy.
            </p>
            <h3 className="mt-2">How do we use your information?</h3>
            <p>Information we collect from you might be used:</p>

            <h3 className="mt-2">How long do we store your information?</h3>
            <p>
              We intend to store some of your information indefinitely. What
              about links to other websites? We may provide links to or
              compatibility with other websites. However, we’re not responsible
              for the privacy practices employed by those websites or the
              information or content they contain.
            </p>

            <h3 className="mt-2">How do we protect your information?</h3>
            <p>
              We use appropriate physical, electronic, and other procedures to
              safeguard and secure the information we collect. However, please
              be aware that the Internet is an inherently unsafe environment,
              and that hackers are constantly working to defeat security
              measures. Thus, we cannot guarantee that your information will not
              be accessed, disclosed, altered or destroyed, and you accept this
              risk.
            </p>

            <h3 className="mt-2">How can you protect your information?</h3>
            <p>
              We urge you to take steps to keep your personal information safe
              by not sharing it with others or posting it online.
            </p>

            <h3 className="mt-2">
              Do we disclose any information to outside parties?
            </h3>
            <p>
              We do not collect, sell, trade, or otherwise transfer to outside
              parties your Personally Identifiable Information (PII) for
              commercial or marketing purposes, except as stated herein.
              Personal information (and non- personal information) collected
              through the Site may be shared with companies and organizations
              that perform services on our behalf (for example, companies that
              provide data management or other support services to us such as
              data storage and Web hosting services). We may share your personal
              information (and non-personal information) with third parties to
              serve you relevant advertising and market our products, but we
              won’t sell your personal information. Information Disclosed in
              Connection with Business Transactions. If we are acquired by a
              third party as a result of a transaction such as a merger,
              acquisition or asset sale or if our assets are acquired by a third
              party in the event we go out of business or enter bankruptcy, some
              or all of our assets, including your PII, may be disclosed or
              transferred to a third-party acquirer in connection with the
              transaction. Information Disclosed for Our Protection and the
              Protection of Others. We cooperate with government and law
              enforcement officials or private parties to enforce and comply
              with the law. We may disclose any information about you to
              government or law enforcement officials or private parties as we,
              in our sole discretion, believe necessary or appropriate: (i) to
              respond to claims, legal process (including subpoenas); (ii) to
              protect our property, rights and safety and the property, rights
              and safety of a third party or the public in general; and (iii) to
              stop any activity that we consider illegal, unethical or legally
              actionable activity.
            </p>

            <h3 className="mt-2">Not Intended for Children</h3>
            <p>
              Our WebApp and Service are not intended for children under the age
              of 16. We do not knowingly or specifically collect information
              from or about children under the age of 16.
            </p>

            <h3 className="mt-2">Changes to our Privacy Policy</h3>
            <p>
              If we decide to change our Privacy Policy, we will post those
              changes on this page. We may also, but are not required to, send
              you an email notice. We encourage you to review this privacy
              policy frequently to be informed of how we are protecting your
              information.
            </p>

            <h3 className="mt-2">Contact Us</h3>
            <p>
              If you have questions about our Privacy Policy, please contact us
              at <a href="mailto:support@eazyfit.app">support@eazyfit.app</a>
            </p>
          </div>
        </div>
      </div>
    </MobileViewLayout>
  );
};

export default Privacy;
