import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesName } from "../../utils/RoutesName";

export const PartTwoIntro = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
    setTimeout(() => {
      navigate(RoutesName.partTwoHeight);
    }, 1300);
  }, []);

  return (
    <div className={`non-animate`}>
      <div
        className="text-center d-flex flex-column justify-content-center"
        style={{ minHeight: "100dvh" }}
      >
        <div className="text-center d-flex flex-column align-items-center ">
          <h1
            style={{
              fontSize: "40px",
              fontWeight: "bold",
              animation: `${isVisible ? "fadeInRight 0.5s ease forwards" : ""}`,
            }}
            className="fw-bold mt-2 animated-text"
          >
            Body
            <br />
            Metrics
          </h1>
          <div
            style={{ width: "68px", position: "relative" }}
            className="d-flex"
          >
            <div
              className={`main-background ${
                isVisible ? "animate-div-bar" : ""
              }`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
