import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DOUBLE from "../../assets/double-bed.svg";
import HOME from "../../assets/home.svg";
import MAT from "../../assets/mat.svg";
import { RoutesName } from "../../utils/RoutesName";
import { MobileViewLayout } from "../../layouts/MobileViewLayout";
import { useDispatch } from "react-redux";
import { setDetailsData } from "../../slices/DetailsSlice";
import ApiService from "../../service/api";

export const PartThreeFour = () => {
  const [selectedIndex, setSelectedIndex] = useState([
    {
      icon: MAT,
      text: "Yoga Mat",
      slogan: "Optimal for any kind of exercises.",
      select: false,
    },
    {
      icon: DOUBLE,
      text: "Bed or Couch",
      slogan: "convenient for same types of exercises.",
      select: false,
    },
    {
      icon: HOME,
      text: "Nothing Specific",
      slogan: "We'll offer the best place to meet your needs.",
      select: false,
    },
  ]);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 2);
  }, []);

  React.useEffect(() => {
    if (location.state?.data) {
      const selectedItem = selectedIndex.find(
        (item) => item.text === location.state.data
      );
      const updatedItems = selectedIndex.map((item) => {
        if (item.text === selectedItem.text) {
          return { ...item, select: true };
        } else {
          return { ...item, select: false };
        }
      });
      setSelectedIndex(updatedItems);
    }
  }, []);

  const updateData = (value) => {
    ApiService.updateGoals({ exerciseSpot: value })
      .then((res) => navigate(-1))
      .catch((err) => {
        console.log(err);
        navigate(-1);
      });
  };

  const handleNextClick = () => {
    const selectedItems = selectedIndex.filter((item) => item.select);
    updateData(selectedItems[0].text);
  };

  return (
    <MobileViewLayout>
      <div className={`animate-from-right ${isVisible ? "slide-in" : ""}`}>
        <div
          className="text-center main-padding d-flex flex-column"
          style={{
            minHeight: "100dvh",
            paddingTop: "45px",
            paddingBottom: "45px",
          }}
        >
          <div style={{ flex: 1 }}>
            <h3 className="fw-medium mt-4 text-center heading-font">
              What's your ideal spot to exercise?
            </h3>
          </div>
          {selectedIndex.map((e, i) => (
            <Tile
              slogan={e.slogan}
              key={i}
              icon={e.icon}
              text={e.text}
              selected={e.select}
              index={i}
              setSelected={setSelectedIndex}
              list={selectedIndex}
              navigate={navigate}
            />
          ))}
          {location.state?.data && (
            <button
              className={`btn w-100 fw-bold mt-4 ${
                location.state?.data ? "profile-button-bg" : "common-btn-color"
              }`}
              style={{ height: "70px" }}
              onClick={handleNextClick}
            >
              {location.state?.data ? "Save" : "Next"}
            </button>
          )}
        </div>
      </div>
    </MobileViewLayout>
  );
};

const Tile = ({
  icon,
  text,
  selected,
  index,
  setSelected,
  list,
  slogan,
  navigate,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const handleSelect = (index) => {
    if (!location.state?.data) {
      setTimeout(() => {
        navigate(RoutesName.partThreeSeven);
      }, 200);
    }

    if (index != 2) {
      let update = [...list];
      update = update.map((e) => {
        return {
          ...e,
          select: false,
        };
      });
      update[index].select = !update[index].select;
      setSelected([...update]);
      dispatch(setDetailsData({ exerciseSpot: update[index].text }));
    } else {
      let update = [...list];
      update = update.map((e) => {
        return {
          ...e,
          select: false,
        };
      });
      update[2].select = true;
      setSelected([...update]);
      dispatch(setDetailsData({ exerciseSpot: update[index].text }));
    }
  };
  return (
    <div
      className={`bg-white d-flex align-items-center mt-2 ${
        selected ? "border-main selected-background" : ""
      }`}
      style={{
        height: "75px",
        width: "100%",
        borderRadius: "15px",
        padding: "20px 20px",
      }}
      onClick={() => {
        handleSelect(index);
      }}
    >
      <img src={icon} alt="" width={32} height={32} />
      <div className="ms-2 text-start">
        <h6 className=" text-start mb-0">{text}</h6>
        <p className="m-0" style={{ fontSize: "12px", color: "#6C6E79" }}>
          {slogan}
        </p>
      </div>
    </div>
  );
};
