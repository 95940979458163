import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

export const MobileViewLayout = ({ children }) => {
  const route = useLocation();

  useEffect(() => {
    if (
      route.pathname === "/register" ||
      route.pathname === "/your-personal-plan" ||
      route.pathname === "/course" ||
      route.pathname === "/meal" ||
      route.pathname === "/manage-subscription"
    ) {
      document.body.style.backgroundColor = "white";
    } else {
      document.body.style.backgroundColor = "rgb(247, 247, 247)";
    }
  }, []);
  return (
    <div className="mobile-view">
      <div className={`container_anim `}>
        <ToastContainer />
        {children}
      </div>
    </div>
  );
};
