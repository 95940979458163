import { createSlice } from "@reduxjs/toolkit";

const mealPlanReducer = createSlice({
  name: "mealPlan",
  initialState: {
    details: null,
  },
  reducers: {
    setMealPlan: (state, action) => {
      state.details = action.payload;
    },
  },
});
const { setMealPlan } = mealPlanReducer.actions;

export const setMealPlanData = (data) => {
  return async (dispatch, getState) => {
    let { details } = getState().mealState;
    let newData = { ...details, ...data };
    dispatch(setMealPlan(newData));
  };
};

export default mealPlanReducer.reducer;
